.cb-anchor-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 24px;
  top: 96px;
  z-index: 2;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-anchor-menu {
    top: 72px;
  }
}
.cb-anchor-menu .cb-menu-list {
  position: absolute;
  background-color: #ffffff;
  top: 49px;
  width: 240px;
  right: -268px;
  transition: right 0.35s ease;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: calc(100vh - 192px);
}
.cb-anchor-menu .cb-menu-list a.cb-menu-link:hover {
  cursor: pointer !important;
}
.cb-anchor-menu .cb-anchor-menu-btn {
  align-self: flex-end;
}
.cb-anchor-menu .cb-anchor-menu-btn:focus {
  background-color: #ffffff;
}
.cb-anchor-menu .cb-anchor-menu-btn.cb-btn-floating {
  border-radius: 8px;
}
.cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon {
  width: 16px;
  height: 16px;
  position: relative;
  transition: 0.5s ease-in-out;
  display: block;
  transform: rotate(0);
}
.cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon span {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #1e1e1e;
  border-radius: 0;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
}
.cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon span:nth-child(1) {
  top: 1px;
}
.cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon span:nth-child(2), .cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon span:nth-child(3) {
  top: 7px;
}
.cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon span:nth-child(4) {
  top: 13px;
}
.cb-anchor-menu .cb-anchor-menu-btn .cb-menu-icon:focus {
  border: 0;
  outline: 0;
}
.cb-anchor-menu.cb-open .cb-anchor-menu-btn span span:nth-child(1), .cb-anchor-menu.cb-open .cb-anchor-menu-btn span span:nth-child(4),
.cb-anchor-menu.cb-open .cb-anchor-menu-btn a span:nth-child(1),
.cb-anchor-menu.cb-open .cb-anchor-menu-btn a span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.cb-anchor-menu.cb-open .cb-anchor-menu-btn span span:nth-child(2),
.cb-anchor-menu.cb-open .cb-anchor-menu-btn a span:nth-child(2) {
  transform: rotate(45deg);
}
.cb-anchor-menu.cb-open .cb-anchor-menu-btn span span:nth-child(3),
.cb-anchor-menu.cb-open .cb-anchor-menu-btn a span:nth-child(3) {
  transform: rotate(-45deg);
}
.cb-anchor-menu.cb-open .cb-menu-list {
  right: 0;
}
.cb-anchor-menu.cb-anchor-bottom {
  top: auto;
  bottom: 96px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-anchor-menu.cb-anchor-bottom {
    bottom: 72px;
  }
}
.cb-anchor-menu.cb-anchor-bottom .cb-menu-list {
  top: auto;
  bottom: 49px;
  max-height: calc(100vh - 240px);
}