@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: #1e1e1e !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  a:not(.btn)[href]::after {
    content: " (" attr(href) ")";
  }
  a:not(.btn)[href^="#"]::after, a:not(.btn)[href^="javascript:"]::after {
    content: "";
  }
  a.cb-no-print-href::after {
    content: "" !important;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
    word-break: break-all;
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #888888;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    max-width: 100%;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h1,
  h2,
  h3 {
    page-break-after: avoid;
  }
  select {
    background: #ffffff !important;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 768px !important;
  }
  .container {
    min-width: 768px !important;
    max-width: 768px !important;
  }
  .cb-btn {
    border: 1px solid #1e1e1e !important;
    border-color: #1e1e1e !important;
    background-color: #ffffff !important;
    color: #1e1e1e;
  }
  .cb-btn .cb-btn-pulse-dots {
    display: none;
  }
  .cb-anchor-menu,
  .cb-back-to-top {
    display: none !important;
  }
  [class^=cb-border] {
    border-color: #1e1e1e !important;
  }
  [class^=cb-border]:not(.cb-border-style) {
    border-width: 1px !important;
  }
  [class^=cb-border].cb-border-style[class^=cb-border-right] {
    border-right-width: 1px !important;
  }
  [class^=cb-border].cb-border-style[class^=cb-border-left] {
    border-left-width: 1px !important;
  }
  [class^=cb-border].cb-border-style[class^=cb-border-top] {
    border-top-width: 1px !important;
  }
  [class^=cb-border].cb-border-style[class^=cb-border-bottom] {
    border-bottom-width: 1px !important;
  }
  .cb-text-list-feature {
    border-color: #000 !important;
  }
  .cb-text-list-feature li {
    border-color: #000 !important;
  }
  .cb-accordion .cb-accordion-controls .cb-accordion-buttons {
    display: none !important;
  }
  .cb-accordion .cb-accordion-panels {
    border-color: #000 !important;
  }
  .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel {
    height: auto !important;
  }
  .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel .cb-accordion-panel-content {
    padding: 24px;
  }
  .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading {
    border-color: #000 !important;
  }
  .cb-accordion-trigger-panel {
    height: auto !important;
    border-color: #000 !important;
  }
  .cb-accordion-trigger-panel .cb-accordion-panel-content {
    padding: 24px;
  }
  .cb-card {
    border: 1px solid #1e1e1e;
    box-shadow: none !important;
  }
  .cb-card a:not(.btn)[href]:after {
    content: "" !important;
  }
  .cb-card-list-vertical .cb-card {
    border-top: 1px solid #d9d9d9;
  }
  .cb-notification {
    border-color: #1e1e1e !important;
  }
  .cb-notification .cb-notification-header .cb-btn {
    display: none !important;
  }
  label {
    background-color: #ffffff !important;
  }
  .cb-input {
    border-color: #1e1e1e !important;
  }
  .cb-input input,
  .cb-input textarea {
    border-color: #1e1e1e !important;
  }
  .cb-select .cb-select-container span.cb-select > span {
    border-color: #1e1e1e !important;
  }
  .cb-select.cb-input-outlined .cb-select-container span.cb-select > span {
    border-color: #1e1e1e !important;
  }
  .cb-select[multiple] {
    border-color: #000 !important;
  }
  .cb-validation-error.cb-input input,
  .cb-validation-error.cb-input textarea,
  .cb-validation-success.cb-input input,
  .cb-validation-success.cb-input textarea {
    border-color: #1e1e1e !important;
  }
  .cb-validation-error.cb-checkbox input[type=checkbox] + span,
  .cb-validation-success.cb-checkbox input[type=checkbox] + span {
    border-color: #1e1e1e !important;
  }
  .cb-validation-error .cb-checkbox input[type=checkbox] + span,
  .cb-validation-success .cb-checkbox input[type=checkbox] + span {
    border-color: #1e1e1e !important;
  }
  .cb-validation-error.cb-radio input[type=radio] + span,
  .cb-validation-success.cb-radio input[type=radio] + span {
    border-color: #1e1e1e !important;
  }
  .cb-validation-error .cb-radio input[type=radio] + span,
  .cb-validation-success .cb-radio input[type=radio] + span {
    border-color: #1e1e1e !important;
  }
  .cb-radio input[type=radio]:checked + span::after {
    border-color: #1e1e1e !important;
  }
  .cb-radio input[type=radio]:checked + span {
    border-color: #1e1e1e !important;
  }
  .cb-radio input[type=radio]:hover:not(:checked) + span, .cb-radio input[type=radio]:focus:not(:checked) + span {
    border: 1px solid #1e1e1e !important;
  }
  .cb-checkbox input[type=checkbox]:checked + span::after {
    background-color: transparent;
    border-color: #1e1e1e !important;
  }
  .cb-checkbox input[type=checkbox]:checked + span {
    border-color: #1e1e1e !important;
  }
  .cb-checkbox input[type=checkbox]:hover:not(:checked) + span, .cb-checkbox input[type=checkbox]:focus:not(:checked) + span {
    border: 1px solid #1e1e1e;
  }
  .cb-toggles-btn input[type=radio] {
    display: none !important;
  }
  .cb-toggles-btn input[type=radio]:checked + span:not(.cb-glyph), .cb-toggles-btn input[type=radio]:checked + span:not(.cb-icon), .cb-toggles-btn input[type=radio]:active + span:not(.cb-glyph), .cb-toggles-btn input[type=radio]:active + span:not(.cb-icon), .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-glyph), .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-icon), .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-glyph), .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-icon) {
    color: #1e1e1e !important;
  }
  .cb-toggles-btn input[type=radio]:checked + span:not(.cb-glyph) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio]:checked + span:not(.cb-glyph) + .cb-icon::before, .cb-toggles-btn input[type=radio]:checked + span:not(.cb-icon) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio]:checked + span:not(.cb-icon) + .cb-icon::before, .cb-toggles-btn input[type=radio]:active + span:not(.cb-glyph) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio]:active + span:not(.cb-glyph) + .cb-icon::before, .cb-toggles-btn input[type=radio]:active + span:not(.cb-icon) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio]:active + span:not(.cb-icon) + .cb-icon::before, .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-glyph) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-glyph) + .cb-icon::before, .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-icon) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-icon) + .cb-icon::before, .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-glyph) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-glyph) + .cb-icon::before, .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-icon) + .cb-glyph::before,
  .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-icon) + .cb-icon::before {
    color: #1e1e1e !important;
  }
  .cb-toggles-btn label input[type=radio] + span:not(.cb-glyph) {
    border-color: #000 !important;
  }
  .cb-toggle-switch .cb-switch {
    display: none !important;
  }
  .cb-menu-list.cb-menu-list-vertical.cb-menu-list-separated ul li + li a {
    border-color: #000;
  }
  .cb-date-time-tags p:first-of-type::after {
    background-color: #000 !important;
  }
  .cb-pagination > li .cb-btn-square {
    text-decoration: none !important;
  }
  .cb-pagination > li .cb-btn-square.cb-active {
    background-color: #1e1e1e !important;
    border-color: #1e1e1e !important;
    color: #ffffff !important;
  }
  .cb-table {
    page-break-inside: auto !important;
    position: relative !important;
    width: 100%;
  }
  .cb-table tr {
    page-break-inside: auto !important;
  }
  .cb-table th,
  .cb-table td {
    border-color: #1e1e1e !important;
    page-break-inside: avoid !important;
  }
  .cb-table-mobile .cb-last-row td,
  .cb-table-mobile .cb-last-row th {
    border-bottom-width: 2px;
  }
  .cb-table-mobile .cb-last-row:last-of-type td,
  .cb-table-mobile .cb-last-row:last-of-type th {
    border-bottom-width: 1px;
  }
  .cb-table-print thead {
    display: none;
  }
  .cb-table-print tbody tr td,
  .cb-table-print tbody tr th {
    border-top: 0;
    display: flex;
    width: 100%;
    position: relative;
  }
  .cb-table-print tbody tr td::before,
  .cb-table-print tbody tr th::before {
    content: attr(data-cb-label);
    width: 50%;
    min-width: 50%;
  }
  .cb-table-print tbody tr td::after,
  .cb-table-print tbody tr th::after {
    content: "";
    border-right: 1px solid #1e1e1e;
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    left: calc(50% - 8px);
    top: 0;
  }
  .cb-table-print tbody tr td:first-child,
  .cb-table-print tbody tr th:first-child {
    padding-top: 24px;
    border-top: 2px solid #1e1e1e;
  }
  .cb-table-print tbody tr td:last-child,
  .cb-table-print tbody tr th:last-child {
    padding-bottom: 24px;
  }
  .cb-table-print tbody tr:first-of-type td:first-child,
  .cb-table-print tbody tr:first-of-type th:first-child {
    padding-top: 11px;
    border-top: 1px solid #1e1e1e;
  }
  .cb-scrollbar-container {
    overflow: unset !important;
    border: 0 !important;
    width: 100%;
  }
  .cb-scrollbar-container .cb-table-responsive {
    width: 100% !important;
    height: auto !important;
    overflow: unset !important;
  }
  .cb-scrollbar-container .cb-table-responsive .cb-scrollbar {
    display: none;
  }
  .cb-scrollbar-container .cb-table-responsive .cb-table {
    width: 100% !important;
    position: relative !important;
  }
  .cb-event-timeline {
    margin-left: 0 !important;
  }
  .cb-event-timeline .cb-gutterh-24 {
    margin-left: 0;
  }
  .cb-event-timeline .cb-gutterh-24 li,
  .cb-event-timeline .cb-gutterh-24 div {
    margin-left: 0;
  }
  .cb-event-timeline > ol {
    flex-wrap: wrap !important;
    padding: 0 !important;
    flex-direction: row !important;
    padding-top: 72px;
  }
  .cb-event-timeline > ol > li {
    width: calc(50% - 24px) !important;
    max-width: calc(50% - 24px) !important;
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    position: relative;
    flex-grow: 1 !important;
    flex-basis: auto !important;
    margin-top: 64px !important;
    margin-bottom: 24px;
  }
  .cb-event-timeline > ol > li::before {
    border-top: 2px solid #000;
    position: absolute;
    display: block;
    content: "";
    height: 2px !important;
    width: calc(100% + 28px) !important;
    top: -24px !important;
    left: 27px !important;
  }
  .cb-event-timeline > ol > li::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 8px solid #000;
    top: -31px !important;
    left: 27px !important;
  }
  .cb-event-timeline > ol > li:nth-child(even) {
    margin-left: 24px;
  }
  .cb-event-timeline > ol > li:nth-child(even)::before {
    width: calc(100% - 43px) !important;
  }
  .cb-event-timeline > ol > li:nth-child(even) .cb-card-header {
    position: relative;
  }
  .cb-event-timeline > ol > li:nth-child(even) .cb-card-header::after {
    font-family: CB Glyphs;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e940";
    position: absolute;
    right: -15px;
    top: -55px;
    width: 16px;
    height: 16px;
    display: block;
  }
  .cb-event-timeline > ol > li:last-child::before {
    width: 0 !important;
  }
  .cb-event-timeline > ol > li:last-child:nth-child(odd)::after {
    border: 0;
  }
  .cb-event-timeline > ol > li:last-child .cb-card-header {
    position: relative;
  }
  .cb-event-timeline > ol > li:last-child .cb-card-header::after {
    content: "";
  }
  .cb-event-timeline .row {
    flex-wrap: wrap !important;
    padding: 0 !important;
    flex-direction: row !important;
    padding-top: 72px;
  }
  .cb-event-timeline .row div[class*=col-] {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    margin-top: 64px !important;
  }
  .cb-event-timeline .row div[class*=col-] .cb-card {
    width: 100%;
    max-width: 100%;
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    position: relative;
    flex-grow: 1 !important;
    flex-basis: auto !important;
    margin-top: 24px !important;
    margin-bottom: 24px;
  }
  .cb-event-timeline .row div[class*=col-] .cb-card::before {
    border-top: 2px solid #000;
    position: absolute;
    display: block;
    content: "";
    height: 2px !important;
    width: calc(100% + 28px) !important;
    top: -24px !important;
    left: 27px !important;
  }
  .cb-event-timeline .row div[class*=col-] .cb-card::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 8px solid #000;
    top: -31px !important;
    left: 24px !important;
  }
  .cb-event-timeline .row div[class*=col-]:nth-child(even) .cb-card::before {
    width: calc(100% - 39px) !important;
  }
  .cb-event-timeline .row div[class*=col-]:nth-child(even) .cb-card .cb-card-header {
    position: relative;
  }
  .cb-event-timeline .row div[class*=col-]:nth-child(even) .cb-card .cb-card-header::after {
    font-family: CB Glyphs;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\e940";
    position: absolute;
    right: -19px;
    top: -55px;
    width: 16px;
    height: 16px;
    display: block;
  }
  .cb-event-timeline .row div[class*=col-]:last-child:nth-child(odd) .cb-card::after {
    border: 0;
  }
  .cb-event-timeline .row div[class*=col-]:last-child .cb-card::before {
    width: 0 !important;
  }
  .cb-event-timeline .row div[class*=col-]:last-child .cb-card .cb-card-header::after {
    content: "";
  }
  .cb-event-timeline .cb-event-year-stamp .cb-event-year {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 32px !important;
    position: absolute;
    top: -72px !important;
    left: 0 !important;
  }
  .cb-event-timeline .cb-meta-tags,
  .cb-event-timeline .cb-card-action,
  .cb-event-timeline .cb-btn {
    display: none !important;
  }
  .cb-event-timeline .cb-card-label {
    font-weight: 500;
    text-align: center;
    color: #ffffff !important;
    background-color: #000 !important;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
  .cb-horizontal-carousel .cb-horizontal-nav-right,
  .cb-horizontal-carousel .cb-horizontal-nav-left {
    border: 0 !important;
  }
  .cb-horizontal-carousel .cb-horizontal-nav-right a,
  .cb-horizontal-carousel .cb-horizontal-nav-left a {
    display: none;
    visibility: hidden;
  }
  .cb-horizontal-carousel .cb-horizontal-carousel-nav {
    overflow: auto !important;
  }
  .cb-horizontal-carousel .cb-horizontal-carousel-content {
    width: 100%;
  }
  .cb-box-shadow,
  .cb-box-shadow-light {
    border: 1px solid #1e1e1e;
  }
  .cb-box-shadow-right,
  .cb-box-shadow-right-light {
    border-right: 1px solid #1e1e1e;
  }
  .cb-box-shadow-left,
  .cb-box-shadow-left-light {
    border-left: 1px solid #1e1e1e;
  }
  .cb-box-shadow-bottom,
  .cb-box-shadow-bottom-light {
    border-bottom: 1px solid #1e1e1e;
  }
  .cb-box-shadow-top,
  .cb-box-shadow-top-light {
    border-top: 1px solid #1e1e1e;
  }
  .cb-popover,
  .cb-tooltip {
    display: none !important;
  }
  .cb-local-navigation {
    position: relative !important;
    background-color: #ffffff;
  }
  .cb-local-navigation .cb-nav-items {
    display: none !important;
  }
  .cb-local-navigation .cb-site-name a::after {
    content: "" !important;
  }
  .cb-main-content,
  main {
    width: 100% !important;
  }
  .cb-dev-env.cb-show-breakpoints::before {
    content: "" !important;
    display: none !important;
    padding: 0;
  }
  .cb-no-print {
    display: none !important;
  }
  .cb-no-print-img img {
    display: none !important;
  }
  .cb-no-print-border {
    border: 0 !important;
  }
  .cb-no-print-href a::after {
    content: "" !important;
  }
  .cb-no-page-break {
    page-break-inside: avoid;
  }
  .cb-page-break {
    page-break-before: always;
    page-break-after: always;
  }
  .cb-page-break-after {
    page-break-after: always;
  }
  .cb-page-break-before {
    page-break-before: always;
  }
  div[id^=cb-atlas-header] {
    border-top: 0 !important;
    border-bottom: 1px solid #000;
  }
  div[id^=cb-atlas-header] a[href="#widget-menu-control"],
  div[id^=cb-atlas-header] a[href="#widget-login-control"],
  div[id^=cb-atlas-header] div[data-cbtrack-widget=search] {
    display: none !important;
  }
  div[id^=cb-atlas-header] a.cb-glyph-logo.cb-logo::after {
    content: "" !important;
  }
  div[id^=cb-atlas-footer] > div:first-of-type {
    border-top: 1px solid #000 !important;
  }
  div[id^=cb-atlas-footer] > div:first-of-type a {
    display: none !important;
  }
  div[id^=cb-atlas-footer] > div:nth-of-type(2) {
    display: none !important;
  }
  div[id^=cb-atlas-footer] a::after {
    content: "" !important;
  }
  .cb-modal-open * {
    visibility: hidden;
  }
  .cb-modal-open .cb-modal.cb-open {
    visibility: visible !important;
  }
  .cb-modal-open .cb-modal.cb-open .cb-modal-overlay {
    visibility: visible !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .cb-modal-open .cb-modal.cb-open .cb-modal-overlay * {
    visibility: visible !important;
  }
  .cb-modal-open .cb-modal.cb-open .cb-modal-overlay .cb-modal-container {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  .cb-modal-open .cb-modal.cb-open .cb-modal-overlay .cb-modal-header .cb-btn-close {
    display: none !important;
  }
  .cb-modal-open .cb-modal.cb-open .cb-modal-overlay .cb-modal-content {
    height: auto !important;
  }
  body {
    font-family: sans-serif;
  }
  h1,
  .cb-h1 {
    font-family: sans-serif;
  }
  h2,
  .cb-h2 {
    font-family: sans-serif;
  }
  h3,
  .cb-h3 {
    font-family: sans-serif;
  }
  h4,
  .cb-h4 {
    font-family: sans-serif;
  }
  h5,
  .cb-h5 {
    font-family: sans-serif;
  }
  h6,
  .cb-h6 {
    font-family: sans-serif;
  }
  .cb-btn,
  .cb-font-small,
  .cb-font-xsmall,
  .cb-font-regular,
  .cb-no-font {
    font-family: sans-serif;
  }
  .cb-btn-row-sm .cb-btn {
    font-family: sans-serif;
  }
}
.cb-print *,
.cb-print *::before,
.cb-print *::after {
  background: transparent !important;
  color: #1e1e1e !important;
  text-shadow: none !important;
  box-shadow: none !important;
}
.cb-print a:not(.btn) {
  text-decoration: underline;
}
.cb-print a:not(.btn)[href]::after {
  content: " (" attr(href) ")";
}
.cb-print a:not(.btn)[href^="#"]::after, .cb-print a:not(.btn)[href^="javascript:"]::after {
  content: "";
}
.cb-print a.cb-no-print-href::after {
  content: "" !important;
}
.cb-print abbr[title]::after {
  content: " (" attr(title) ")";
  word-break: break-all;
}
.cb-print pre {
  white-space: pre-wrap !important;
}
.cb-print pre,
.cb-print blockquote {
  border: 1px solid #888888;
  page-break-inside: avoid;
}
.cb-print img {
  page-break-inside: avoid;
  max-width: 100%;
}
.cb-print p,
.cb-print h2,
.cb-print h3 {
  orphans: 3;
  widows: 3;
}
.cb-print h1,
.cb-print h2,
.cb-print h3 {
  page-break-after: avoid;
}
.cb-print select {
  background: #ffffff !important;
}
@page {
  .cb-print {
    size: a3;
  }
}
.cb-print body {
  min-width: 768px !important;
}
.cb-print .container {
  min-width: 768px !important;
  max-width: 768px !important;
}
.cb-print .cb-btn {
  border: 1px solid #1e1e1e !important;
  border-color: #1e1e1e !important;
  background-color: #ffffff !important;
  color: #1e1e1e;
}
.cb-print .cb-btn .cb-btn-pulse-dots {
  display: none;
}
.cb-print .cb-anchor-menu,
.cb-print .cb-back-to-top {
  display: none !important;
}
.cb-print [class^=cb-border] {
  border-color: #1e1e1e !important;
}
.cb-print [class^=cb-border]:not(.cb-border-style) {
  border-width: 1px !important;
}
.cb-print [class^=cb-border].cb-border-style[class^=cb-border-right] {
  border-right-width: 1px !important;
}
.cb-print [class^=cb-border].cb-border-style[class^=cb-border-left] {
  border-left-width: 1px !important;
}
.cb-print [class^=cb-border].cb-border-style[class^=cb-border-top] {
  border-top-width: 1px !important;
}
.cb-print [class^=cb-border].cb-border-style[class^=cb-border-bottom] {
  border-bottom-width: 1px !important;
}
.cb-print .cb-text-list-feature {
  border-color: #000 !important;
}
.cb-print .cb-text-list-feature li {
  border-color: #000 !important;
}
.cb-print .cb-accordion .cb-accordion-controls .cb-accordion-buttons {
  display: none !important;
}
.cb-print .cb-accordion .cb-accordion-panels {
  border-color: #000 !important;
}
.cb-print .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel {
  height: auto !important;
}
.cb-print .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel .cb-accordion-panel-content {
  padding: 24px;
}
.cb-print .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading {
  border-color: #000 !important;
}
.cb-print .cb-accordion-trigger-panel {
  height: auto !important;
  border-color: #000 !important;
}
.cb-print .cb-accordion-trigger-panel .cb-accordion-panel-content {
  padding: 24px;
}
.cb-print .cb-card {
  border: 1px solid #1e1e1e;
  box-shadow: none !important;
}
.cb-print .cb-card a:not(.btn)[href]:after {
  content: "" !important;
}
.cb-print .cb-card-list-vertical .cb-card {
  border-top: 1px solid #d9d9d9;
}
.cb-print .cb-notification {
  border-color: #1e1e1e !important;
}
.cb-print .cb-notification .cb-notification-header .cb-btn {
  display: none !important;
}
.cb-print label {
  background-color: #ffffff !important;
}
.cb-print .cb-input {
  border-color: #1e1e1e !important;
}
.cb-print .cb-input input,
.cb-print .cb-input textarea {
  border-color: #1e1e1e !important;
}
.cb-print .cb-select .cb-select-container span.cb-select > span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-select.cb-input-outlined .cb-select-container span.cb-select > span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-select[multiple] {
  border-color: #000 !important;
}
.cb-print .cb-validation-error.cb-input input,
.cb-print .cb-validation-error.cb-input textarea,
.cb-print .cb-validation-success.cb-input input,
.cb-print .cb-validation-success.cb-input textarea {
  border-color: #1e1e1e !important;
}
.cb-print .cb-validation-error.cb-checkbox input[type=checkbox] + span,
.cb-print .cb-validation-success.cb-checkbox input[type=checkbox] + span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-validation-error .cb-checkbox input[type=checkbox] + span,
.cb-print .cb-validation-success .cb-checkbox input[type=checkbox] + span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-validation-error.cb-radio input[type=radio] + span,
.cb-print .cb-validation-success.cb-radio input[type=radio] + span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-validation-error .cb-radio input[type=radio] + span,
.cb-print .cb-validation-success .cb-radio input[type=radio] + span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-radio input[type=radio]:checked + span::after {
  border-color: #1e1e1e !important;
}
.cb-print .cb-radio input[type=radio]:checked + span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-radio input[type=radio]:hover:not(:checked) + span, .cb-print .cb-radio input[type=radio]:focus:not(:checked) + span {
  border: 1px solid #1e1e1e !important;
}
.cb-print .cb-checkbox input[type=checkbox]:checked + span::after {
  background-color: transparent;
  border-color: #1e1e1e !important;
}
.cb-print .cb-checkbox input[type=checkbox]:checked + span {
  border-color: #1e1e1e !important;
}
.cb-print .cb-checkbox input[type=checkbox]:hover:not(:checked) + span, .cb-print .cb-checkbox input[type=checkbox]:focus:not(:checked) + span {
  border: 1px solid #1e1e1e;
}
.cb-print .cb-toggles-btn input[type=radio] {
  display: none !important;
}
.cb-print .cb-toggles-btn input[type=radio]:checked + span:not(.cb-glyph), .cb-print .cb-toggles-btn input[type=radio]:checked + span:not(.cb-icon), .cb-print .cb-toggles-btn input[type=radio]:active + span:not(.cb-glyph), .cb-print .cb-toggles-btn input[type=radio]:active + span:not(.cb-icon), .cb-print .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-glyph), .cb-print .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-icon), .cb-print .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-glyph), .cb-print .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-icon) {
  color: #1e1e1e !important;
}
.cb-print .cb-toggles-btn input[type=radio]:checked + span:not(.cb-glyph) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio]:checked + span:not(.cb-glyph) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio]:checked + span:not(.cb-icon) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio]:checked + span:not(.cb-icon) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio]:active + span:not(.cb-glyph) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio]:active + span:not(.cb-glyph) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio]:active + span:not(.cb-icon) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio]:active + span:not(.cb-icon) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-glyph) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-glyph) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-icon) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio].cb-checked + span:not(.cb-icon) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-glyph) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-glyph) + .cb-icon::before, .cb-print .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-icon) + .cb-glyph::before,
.cb-print .cb-toggles-btn input[type=radio].cb-active + span:not(.cb-icon) + .cb-icon::before {
  color: #1e1e1e !important;
}
.cb-print .cb-toggles-btn label input[type=radio] + span:not(.cb-glyph) {
  border-color: #000 !important;
}
.cb-print .cb-toggle-switch .cb-switch {
  display: none !important;
}
.cb-print .cb-menu-list.cb-menu-list-vertical.cb-menu-list-separated ul li + li a {
  border-color: #000;
}
.cb-print .cb-date-time-tags p:first-of-type::after {
  background-color: #000 !important;
}
.cb-print .cb-pagination > li .cb-btn-square {
  text-decoration: none !important;
}
.cb-print .cb-pagination > li .cb-btn-square.cb-active {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
  color: #ffffff !important;
}
.cb-print .cb-table {
  page-break-inside: auto !important;
  position: relative !important;
  width: 100%;
}
.cb-print .cb-table tr {
  page-break-inside: auto !important;
}
.cb-print .cb-table th,
.cb-print .cb-table td {
  border-color: #1e1e1e !important;
  page-break-inside: avoid !important;
}
.cb-print .cb-table-mobile .cb-last-row td,
.cb-print .cb-table-mobile .cb-last-row th {
  border-bottom-width: 2px;
}
.cb-print .cb-table-mobile .cb-last-row:last-of-type td,
.cb-print .cb-table-mobile .cb-last-row:last-of-type th {
  border-bottom-width: 1px;
}
.cb-print .cb-table-print thead {
  display: none;
}
.cb-print .cb-table-print tbody tr td,
.cb-print .cb-table-print tbody tr th {
  border-top: 0;
  display: flex;
  width: 100%;
  position: relative;
}
.cb-print .cb-table-print tbody tr td::before,
.cb-print .cb-table-print tbody tr th::before {
  content: attr(data-cb-label);
  width: 50%;
  min-width: 50%;
}
.cb-print .cb-table-print tbody tr td::after,
.cb-print .cb-table-print tbody tr th::after {
  content: "";
  border-right: 1px solid #1e1e1e;
  width: 1px;
  height: 100%;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
  top: 0;
}
.cb-print .cb-table-print tbody tr td:first-child,
.cb-print .cb-table-print tbody tr th:first-child {
  padding-top: 24px;
  border-top: 2px solid #1e1e1e;
}
.cb-print .cb-table-print tbody tr td:last-child,
.cb-print .cb-table-print tbody tr th:last-child {
  padding-bottom: 24px;
}
.cb-print .cb-table-print tbody tr:first-of-type td:first-child,
.cb-print .cb-table-print tbody tr:first-of-type th:first-child {
  padding-top: 11px;
  border-top: 1px solid #1e1e1e;
}
.cb-print .cb-scrollbar-container {
  overflow: unset !important;
  border: 0 !important;
  width: 100%;
}
.cb-print .cb-scrollbar-container .cb-table-responsive {
  width: 100% !important;
  height: auto !important;
  overflow: unset !important;
}
.cb-print .cb-scrollbar-container .cb-table-responsive .cb-scrollbar {
  display: none;
}
.cb-print .cb-scrollbar-container .cb-table-responsive .cb-table {
  width: 100% !important;
  position: relative !important;
}
.cb-print .cb-event-timeline {
  margin-left: 0 !important;
}
.cb-print .cb-event-timeline .cb-gutterh-24 {
  margin-left: 0;
}
.cb-print .cb-event-timeline .cb-gutterh-24 li,
.cb-print .cb-event-timeline .cb-gutterh-24 div {
  margin-left: 0;
}
.cb-print .cb-event-timeline > ol {
  flex-wrap: wrap !important;
  padding: 0 !important;
  flex-direction: row !important;
  padding-top: 72px;
}
.cb-print .cb-event-timeline > ol > li {
  width: calc(50% - 24px) !important;
  max-width: calc(50% - 24px) !important;
  break-inside: avoid !important;
  page-break-inside: avoid !important;
  position: relative;
  flex-grow: 1 !important;
  flex-basis: auto !important;
  margin-top: 64px !important;
  margin-bottom: 24px;
}
.cb-print .cb-event-timeline > ol > li::before {
  border-top: 2px solid #000;
  position: absolute;
  display: block;
  content: "";
  height: 2px !important;
  width: calc(100% + 28px) !important;
  top: -24px !important;
  left: 27px !important;
}
.cb-print .cb-event-timeline > ol > li::after {
  position: absolute;
  display: block;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: 8px solid #000;
  top: -31px !important;
  left: 27px !important;
}
.cb-print .cb-event-timeline > ol > li:nth-child(even) {
  margin-left: 24px;
}
.cb-print .cb-event-timeline > ol > li:nth-child(even)::before {
  width: calc(100% - 43px) !important;
}
.cb-print .cb-event-timeline > ol > li:nth-child(even) .cb-card-header {
  position: relative;
}
.cb-print .cb-event-timeline > ol > li:nth-child(even) .cb-card-header::after {
  font-family: CB Glyphs;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e940";
  position: absolute;
  right: -15px;
  top: -55px;
  width: 16px;
  height: 16px;
  display: block;
}
.cb-print .cb-event-timeline > ol > li:last-child::before {
  width: 0 !important;
}
.cb-print .cb-event-timeline > ol > li:last-child:nth-child(odd)::after {
  border: 0;
}
.cb-print .cb-event-timeline > ol > li:last-child .cb-card-header {
  position: relative;
}
.cb-print .cb-event-timeline > ol > li:last-child .cb-card-header::after {
  content: "";
}
.cb-print .cb-event-timeline .row {
  flex-wrap: wrap !important;
  padding: 0 !important;
  flex-direction: row !important;
  padding-top: 72px;
}
.cb-print .cb-event-timeline .row div[class*=col-] {
  flex: 0 0 50% !important;
  max-width: 50% !important;
  margin-top: 64px !important;
}
.cb-print .cb-event-timeline .row div[class*=col-] .cb-card {
  width: 100%;
  max-width: 100%;
  break-inside: avoid !important;
  page-break-inside: avoid !important;
  position: relative;
  flex-grow: 1 !important;
  flex-basis: auto !important;
  margin-top: 24px !important;
  margin-bottom: 24px;
}
.cb-print .cb-event-timeline .row div[class*=col-] .cb-card::before {
  border-top: 2px solid #000;
  position: absolute;
  display: block;
  content: "";
  height: 2px !important;
  width: calc(100% + 28px) !important;
  top: -24px !important;
  left: 27px !important;
}
.cb-print .cb-event-timeline .row div[class*=col-] .cb-card::after {
  position: absolute;
  display: block;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: 8px solid #000;
  top: -31px !important;
  left: 24px !important;
}
.cb-print .cb-event-timeline .row div[class*=col-]:nth-child(even) .cb-card::before {
  width: calc(100% - 39px) !important;
}
.cb-print .cb-event-timeline .row div[class*=col-]:nth-child(even) .cb-card .cb-card-header {
  position: relative;
}
.cb-print .cb-event-timeline .row div[class*=col-]:nth-child(even) .cb-card .cb-card-header::after {
  font-family: CB Glyphs;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e940";
  position: absolute;
  right: -19px;
  top: -55px;
  width: 16px;
  height: 16px;
  display: block;
}
.cb-print .cb-event-timeline .row div[class*=col-]:last-child:nth-child(odd) .cb-card::after {
  border: 0;
}
.cb-print .cb-event-timeline .row div[class*=col-]:last-child .cb-card::before {
  width: 0 !important;
}
.cb-print .cb-event-timeline .row div[class*=col-]:last-child .cb-card .cb-card-header::after {
  content: "";
}
.cb-print .cb-event-timeline .cb-event-year-stamp .cb-event-year {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px !important;
  position: absolute;
  top: -72px !important;
  left: 0 !important;
}
.cb-print .cb-event-timeline .cb-meta-tags,
.cb-print .cb-event-timeline .cb-card-action,
.cb-print .cb-event-timeline .cb-btn {
  display: none !important;
}
.cb-print .cb-event-timeline .cb-card-label {
  font-weight: 500;
  text-align: center;
  color: #ffffff !important;
  background-color: #000 !important;
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
}
.cb-print .cb-horizontal-carousel .cb-horizontal-nav-right,
.cb-print .cb-horizontal-carousel .cb-horizontal-nav-left {
  border: 0 !important;
}
.cb-print .cb-horizontal-carousel .cb-horizontal-nav-right a,
.cb-print .cb-horizontal-carousel .cb-horizontal-nav-left a {
  display: none;
  visibility: hidden;
}
.cb-print .cb-horizontal-carousel .cb-horizontal-carousel-nav {
  overflow: auto !important;
}
.cb-print .cb-horizontal-carousel .cb-horizontal-carousel-content {
  width: 100%;
}
.cb-print .cb-box-shadow,
.cb-print .cb-box-shadow-light {
  border: 1px solid #1e1e1e;
}
.cb-print .cb-box-shadow-right,
.cb-print .cb-box-shadow-right-light {
  border-right: 1px solid #1e1e1e;
}
.cb-print .cb-box-shadow-left,
.cb-print .cb-box-shadow-left-light {
  border-left: 1px solid #1e1e1e;
}
.cb-print .cb-box-shadow-bottom,
.cb-print .cb-box-shadow-bottom-light {
  border-bottom: 1px solid #1e1e1e;
}
.cb-print .cb-box-shadow-top,
.cb-print .cb-box-shadow-top-light {
  border-top: 1px solid #1e1e1e;
}
.cb-print .cb-popover,
.cb-print .cb-tooltip {
  display: none !important;
}
.cb-print .cb-local-navigation {
  position: relative !important;
  background-color: #ffffff;
}
.cb-print .cb-local-navigation .cb-nav-items {
  display: none !important;
}
.cb-print .cb-local-navigation .cb-site-name a::after {
  content: "" !important;
}
.cb-print .cb-main-content,
.cb-print main {
  width: 100% !important;
}
.cb-print .cb-dev-env.cb-show-breakpoints::before {
  content: "" !important;
  display: none !important;
  padding: 0;
}
.cb-print .cb-no-print {
  display: none !important;
}
.cb-print .cb-no-print-img img {
  display: none !important;
}
.cb-print .cb-no-print-border {
  border: 0 !important;
}
.cb-print .cb-no-print-href a::after {
  content: "" !important;
}
.cb-print .cb-no-page-break {
  page-break-inside: avoid;
}
.cb-print .cb-page-break {
  page-break-before: always;
  page-break-after: always;
}
.cb-print .cb-page-break-after {
  page-break-after: always;
}
.cb-print .cb-page-break-before {
  page-break-before: always;
}
.cb-print div[id^=cb-atlas-header] {
  border-top: 0 !important;
  border-bottom: 1px solid #000;
}
.cb-print div[id^=cb-atlas-header] a[href="#widget-menu-control"],
.cb-print div[id^=cb-atlas-header] a[href="#widget-login-control"],
.cb-print div[id^=cb-atlas-header] div[data-cbtrack-widget=search] {
  display: none !important;
}
.cb-print div[id^=cb-atlas-header] a.cb-glyph-logo.cb-logo::after {
  content: "" !important;
}
.cb-print div[id^=cb-atlas-footer] > div:first-of-type {
  border-top: 1px solid #000 !important;
}
.cb-print div[id^=cb-atlas-footer] > div:first-of-type a {
  display: none !important;
}
.cb-print div[id^=cb-atlas-footer] > div:nth-of-type(2) {
  display: none !important;
}
.cb-print div[id^=cb-atlas-footer] a::after {
  content: "" !important;
}
.cb-print body {
  font-family: sans-serif;
}
.cb-print h1,
.cb-print .cb-h1 {
  font-family: sans-serif;
}
.cb-print h2,
.cb-print .cb-h2 {
  font-family: sans-serif;
}
.cb-print h3,
.cb-print .cb-h3 {
  font-family: sans-serif;
}
.cb-print h4,
.cb-print .cb-h4 {
  font-family: sans-serif;
}
.cb-print h5,
.cb-print .cb-h5 {
  font-family: sans-serif;
}
.cb-print h6,
.cb-print .cb-h6 {
  font-family: sans-serif;
}
.cb-print .cb-btn,
.cb-print .cb-font-small,
.cb-print .cb-font-xsmall,
.cb-print .cb-font-regular,
.cb-print .cb-no-font {
  font-family: sans-serif;
}
.cb-print .cb-btn-row-sm .cb-btn {
  font-family: sans-serif;
}
.cb-print.cb-modal-open * {
  visibility: hidden;
}
.cb-print.cb-modal-open .cb-modal.cb-open {
  visibility: visible !important;
}
.cb-print.cb-modal-open .cb-modal.cb-open .cb-modal-overlay {
  visibility: visible !important;
  justify-content: flex-start;
  align-items: flex-start;
}
.cb-print.cb-modal-open .cb-modal.cb-open .cb-modal-overlay * {
  visibility: visible !important;
}
.cb-print.cb-modal-open .cb-modal.cb-open .cb-modal-overlay .cb-modal-container {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.cb-print.cb-modal-open .cb-modal.cb-open .cb-modal-overlay .cb-modal-header .cb-btn-close {
  display: none !important;
}
.cb-print.cb-modal-open .cb-modal.cb-open .cb-modal-overlay .cb-modal-content {
  height: auto !important;
}

@media print {
  body.cb-print-full {
    min-width: 976px !important;
  }
  .cb-print-full .container {
    min-width: 976px !important;
    max-width: 976px !important;
  }
  body.cb-print-col-2 {
    min-width: 976px !important;
  }
  .cb-print-col-2 .container {
    min-width: 976px !important;
    max-width: 976px !important;
  }
  .cb-print-col-2 .cb-band .container {
    min-width: auto !important;
    max-width: auto !important;
  }
  .cb-print-col-2 .col-xs-1 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-2 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-3 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-4 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-5 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-6 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-7 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-8 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-9 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-10 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-11 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs-12 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .cb-print-col-2 .col-xs,
  .cb-print-col-2 .col-xs-auto {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print and (min-width: 1px) {
  .cb-print-col-2 .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .cb-print-col-2 .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .cb-print-col-2 .col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cb-print-col-2 .col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cb-print-col-2 .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cb-print-col-2 .col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cb-print-col-2 .col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cb-print-col-2 .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cb-print-col-2 .col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cb-print-col-2 .col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cb-print-col-2 .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cb-print-col-2 .col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cb-print-col-2 .col-xs-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cb-print-col-2 .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cb-print-col-2 .order-xs-first {
    order: -1;
  }
  .cb-print-col-2 .order-xs-last {
    order: 13;
  }
  .cb-print-col-2 .order-xs-0 {
    order: 0;
  }
  .cb-print-col-2 .order-xs-1 {
    order: 1;
  }
  .cb-print-col-2 .order-xs-2 {
    order: 2;
  }
  .cb-print-col-2 .order-xs-3 {
    order: 3;
  }
  .cb-print-col-2 .order-xs-4 {
    order: 4;
  }
  .cb-print-col-2 .order-xs-5 {
    order: 5;
  }
  .cb-print-col-2 .order-xs-6 {
    order: 6;
  }
  .cb-print-col-2 .order-xs-7 {
    order: 7;
  }
  .cb-print-col-2 .order-xs-8 {
    order: 8;
  }
  .cb-print-col-2 .order-xs-9 {
    order: 9;
  }
  .cb-print-col-2 .order-xs-10 {
    order: 10;
  }
  .cb-print-col-2 .order-xs-11 {
    order: 11;
  }
  .cb-print-col-2 .order-xs-12 {
    order: 12;
  }
  .cb-print-col-2 .offset-xs-0 {
    margin-left: 0;
  }
  .cb-print-col-2 .offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .cb-print-col-2 .offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .cb-print-col-2 .offset-xs-3 {
    margin-left: 25%;
  }
  .cb-print-col-2 .offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .cb-print-col-2 .offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .cb-print-col-2 .offset-xs-6 {
    margin-left: 50%;
  }
  .cb-print-col-2 .offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .cb-print-col-2 .offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .cb-print-col-2 .offset-xs-9 {
    margin-left: 75%;
  }
  .cb-print-col-2 .offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .cb-print-col-2 .offset-xs-11 {
    margin-left: 91.6666666667%;
  }
}
@media print and (min-width: 1px) and (max-width: 0.98px) {
  .cb-print-col-2 .order-xs-first-only {
    order: -1;
  }
  .cb-print-col-2 .order-xs-last-only {
    order: 13;
  }
  .cb-print-col-2 .order-xs-0-only {
    order: 0;
  }
  .cb-print-col-2 .order-xs-1-only {
    order: 1;
  }
  .cb-print-col-2 .order-xs-2-only {
    order: 2;
  }
  .cb-print-col-2 .order-xs-3-only {
    order: 3;
  }
  .cb-print-col-2 .order-xs-4-only {
    order: 4;
  }
  .cb-print-col-2 .order-xs-5-only {
    order: 5;
  }
  .cb-print-col-2 .order-xs-6-only {
    order: 6;
  }
  .cb-print-col-2 .order-xs-7-only {
    order: 7;
  }
  .cb-print-col-2 .order-xs-8-only {
    order: 8;
  }
  .cb-print-col-2 .order-xs-9-only {
    order: 9;
  }
  .cb-print-col-2 .order-xs-10-only {
    order: 10;
  }
  .cb-print-col-2 .order-xs-11-only {
    order: 11;
  }
  .cb-print-col-2 .order-xs-12-only {
    order: 12;
  }
}
@media print {
  .cb-print-col-2 .col-sm-1 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-2 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-3 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-4 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-5 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-6 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-7 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-8 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-9 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-10 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-11 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm-12 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-sm,
  .cb-print-col-2 .col-sm-auto {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print and (min-width: 1px) {
  .cb-print-col-2 .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .cb-print-col-2 .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .cb-print-col-2 .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cb-print-col-2 .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cb-print-col-2 .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cb-print-col-2 .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cb-print-col-2 .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cb-print-col-2 .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cb-print-col-2 .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cb-print-col-2 .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cb-print-col-2 .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cb-print-col-2 .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cb-print-col-2 .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cb-print-col-2 .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cb-print-col-2 .order-sm-first {
    order: -1;
  }
  .cb-print-col-2 .order-sm-last {
    order: 13;
  }
  .cb-print-col-2 .order-sm-0 {
    order: 0;
  }
  .cb-print-col-2 .order-sm-1 {
    order: 1;
  }
  .cb-print-col-2 .order-sm-2 {
    order: 2;
  }
  .cb-print-col-2 .order-sm-3 {
    order: 3;
  }
  .cb-print-col-2 .order-sm-4 {
    order: 4;
  }
  .cb-print-col-2 .order-sm-5 {
    order: 5;
  }
  .cb-print-col-2 .order-sm-6 {
    order: 6;
  }
  .cb-print-col-2 .order-sm-7 {
    order: 7;
  }
  .cb-print-col-2 .order-sm-8 {
    order: 8;
  }
  .cb-print-col-2 .order-sm-9 {
    order: 9;
  }
  .cb-print-col-2 .order-sm-10 {
    order: 10;
  }
  .cb-print-col-2 .order-sm-11 {
    order: 11;
  }
  .cb-print-col-2 .order-sm-12 {
    order: 12;
  }
  .cb-print-col-2 .offset-sm-0 {
    margin-left: 0;
  }
  .cb-print-col-2 .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .cb-print-col-2 .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .cb-print-col-2 .offset-sm-3 {
    margin-left: 25%;
  }
  .cb-print-col-2 .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .cb-print-col-2 .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .cb-print-col-2 .offset-sm-6 {
    margin-left: 50%;
  }
  .cb-print-col-2 .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .cb-print-col-2 .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .cb-print-col-2 .offset-sm-9 {
    margin-left: 75%;
  }
  .cb-print-col-2 .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .cb-print-col-2 .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media print and (min-width: 1px) and (max-width: 0.98px) {
  .cb-print-col-2 .order-sm-first-only {
    order: -1;
  }
  .cb-print-col-2 .order-sm-last-only {
    order: 13;
  }
  .cb-print-col-2 .order-sm-0-only {
    order: 0;
  }
  .cb-print-col-2 .order-sm-1-only {
    order: 1;
  }
  .cb-print-col-2 .order-sm-2-only {
    order: 2;
  }
  .cb-print-col-2 .order-sm-3-only {
    order: 3;
  }
  .cb-print-col-2 .order-sm-4-only {
    order: 4;
  }
  .cb-print-col-2 .order-sm-5-only {
    order: 5;
  }
  .cb-print-col-2 .order-sm-6-only {
    order: 6;
  }
  .cb-print-col-2 .order-sm-7-only {
    order: 7;
  }
  .cb-print-col-2 .order-sm-8-only {
    order: 8;
  }
  .cb-print-col-2 .order-sm-9-only {
    order: 9;
  }
  .cb-print-col-2 .order-sm-10-only {
    order: 10;
  }
  .cb-print-col-2 .order-sm-11-only {
    order: 11;
  }
  .cb-print-col-2 .order-sm-12-only {
    order: 12;
  }
}
@media print {
  .cb-print-col-2 .col-md-1 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-2 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-3 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-4 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-5 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-6 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-7 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-8 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-9 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-10 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-11 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md-12 {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print {
  .cb-print-col-2 .col-md,
  .cb-print-col-2 .col-md-auto {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media print and (min-width: 1px) {
  .cb-print-col-2 .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .cb-print-col-2 .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .cb-print-col-2 .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cb-print-col-2 .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cb-print-col-2 .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cb-print-col-2 .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cb-print-col-2 .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cb-print-col-2 .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cb-print-col-2 .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cb-print-col-2 .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cb-print-col-2 .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cb-print-col-2 .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cb-print-col-2 .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cb-print-col-2 .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cb-print-col-2 .order-md-first {
    order: -1;
  }
  .cb-print-col-2 .order-md-last {
    order: 13;
  }
  .cb-print-col-2 .order-md-0 {
    order: 0;
  }
  .cb-print-col-2 .order-md-1 {
    order: 1;
  }
  .cb-print-col-2 .order-md-2 {
    order: 2;
  }
  .cb-print-col-2 .order-md-3 {
    order: 3;
  }
  .cb-print-col-2 .order-md-4 {
    order: 4;
  }
  .cb-print-col-2 .order-md-5 {
    order: 5;
  }
  .cb-print-col-2 .order-md-6 {
    order: 6;
  }
  .cb-print-col-2 .order-md-7 {
    order: 7;
  }
  .cb-print-col-2 .order-md-8 {
    order: 8;
  }
  .cb-print-col-2 .order-md-9 {
    order: 9;
  }
  .cb-print-col-2 .order-md-10 {
    order: 10;
  }
  .cb-print-col-2 .order-md-11 {
    order: 11;
  }
  .cb-print-col-2 .order-md-12 {
    order: 12;
  }
  .cb-print-col-2 .offset-md-0 {
    margin-left: 0;
  }
  .cb-print-col-2 .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .cb-print-col-2 .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .cb-print-col-2 .offset-md-3 {
    margin-left: 25%;
  }
  .cb-print-col-2 .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .cb-print-col-2 .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .cb-print-col-2 .offset-md-6 {
    margin-left: 50%;
  }
  .cb-print-col-2 .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .cb-print-col-2 .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .cb-print-col-2 .offset-md-9 {
    margin-left: 75%;
  }
  .cb-print-col-2 .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .cb-print-col-2 .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media print and (min-width: 1px) {
  .cb-print-col-2 .order-md-first-only {
    order: -1;
  }
  .cb-print-col-2 .order-md-last-only {
    order: 13;
  }
  .cb-print-col-2 .order-md-0-only {
    order: 0;
  }
  .cb-print-col-2 .order-md-1-only {
    order: 1;
  }
  .cb-print-col-2 .order-md-2-only {
    order: 2;
  }
  .cb-print-col-2 .order-md-3-only {
    order: 3;
  }
  .cb-print-col-2 .order-md-4-only {
    order: 4;
  }
  .cb-print-col-2 .order-md-5-only {
    order: 5;
  }
  .cb-print-col-2 .order-md-6-only {
    order: 6;
  }
  .cb-print-col-2 .order-md-7-only {
    order: 7;
  }
  .cb-print-col-2 .order-md-8-only {
    order: 8;
  }
  .cb-print-col-2 .order-md-9-only {
    order: 9;
  }
  .cb-print-col-2 .order-md-10-only {
    order: 10;
  }
  .cb-print-col-2 .order-md-11-only {
    order: 11;
  }
  .cb-print-col-2 .order-md-12-only {
    order: 12;
  }
}
@media print {
  .cb-print-col-2 .cb-print-all {
    display: block !important;
  }
}
.cb-print.cb-print-full {
  min-width: 976px !important;
}
.cb-print.cb-print-full .container {
  min-width: 976px !important;
  max-width: 976px !important;
}
.cb-print.cb-print-col-2 {
  min-width: 976px !important;
}
.cb-print.cb-print-col-2 .container {
  min-width: 976px !important;
  max-width: 976px !important;
}
.cb-print.cb-print-col-2 .container {
  min-width: 976px !important;
  max-width: 976px !important;
}
.cb-print.cb-print-col-2 .cb-band .container {
  min-width: auto !important;
  max-width: auto !important;
}
.cb-print.cb-print-col-2 .col-xs-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-2 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-3 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-4 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-5 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-6 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-7 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-8 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-9 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-10 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-11 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs-12 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-xs,
.cb-print.cb-print-col-2 .col-xs-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 1px) {
  .cb-print.cb-print-col-2 .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cb-print.cb-print-col-2 .col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cb-print.cb-print-col-2 .col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cb-print.cb-print-col-2 .col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-xs-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .order-xs-first {
    order: -1;
  }
  .cb-print.cb-print-col-2 .order-xs-last {
    order: 13;
  }
  .cb-print.cb-print-col-2 .order-xs-0 {
    order: 0;
  }
  .cb-print.cb-print-col-2 .order-xs-1 {
    order: 1;
  }
  .cb-print.cb-print-col-2 .order-xs-2 {
    order: 2;
  }
  .cb-print.cb-print-col-2 .order-xs-3 {
    order: 3;
  }
  .cb-print.cb-print-col-2 .order-xs-4 {
    order: 4;
  }
  .cb-print.cb-print-col-2 .order-xs-5 {
    order: 5;
  }
  .cb-print.cb-print-col-2 .order-xs-6 {
    order: 6;
  }
  .cb-print.cb-print-col-2 .order-xs-7 {
    order: 7;
  }
  .cb-print.cb-print-col-2 .order-xs-8 {
    order: 8;
  }
  .cb-print.cb-print-col-2 .order-xs-9 {
    order: 9;
  }
  .cb-print.cb-print-col-2 .order-xs-10 {
    order: 10;
  }
  .cb-print.cb-print-col-2 .order-xs-11 {
    order: 11;
  }
  .cb-print.cb-print-col-2 .order-xs-12 {
    order: 12;
  }
  .cb-print.cb-print-col-2 .offset-xs-0 {
    margin-left: 0;
  }
  .cb-print.cb-print-col-2 .offset-xs-1 {
    margin-left: 8.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-xs-2 {
    margin-left: 16.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-xs-3 {
    margin-left: 25%;
  }
  .cb-print.cb-print-col-2 .offset-xs-4 {
    margin-left: 33.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-xs-5 {
    margin-left: 41.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-xs-6 {
    margin-left: 50%;
  }
  .cb-print.cb-print-col-2 .offset-xs-7 {
    margin-left: 58.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-xs-8 {
    margin-left: 66.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-xs-9 {
    margin-left: 75%;
  }
  .cb-print.cb-print-col-2 .offset-xs-10 {
    margin-left: 83.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-xs-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1px) and (max-width: 0.98px) {
  .cb-print.cb-print-col-2 .order-xs-first-only {
    order: -1;
  }
  .cb-print.cb-print-col-2 .order-xs-last-only {
    order: 13;
  }
  .cb-print.cb-print-col-2 .order-xs-0-only {
    order: 0;
  }
  .cb-print.cb-print-col-2 .order-xs-1-only {
    order: 1;
  }
  .cb-print.cb-print-col-2 .order-xs-2-only {
    order: 2;
  }
  .cb-print.cb-print-col-2 .order-xs-3-only {
    order: 3;
  }
  .cb-print.cb-print-col-2 .order-xs-4-only {
    order: 4;
  }
  .cb-print.cb-print-col-2 .order-xs-5-only {
    order: 5;
  }
  .cb-print.cb-print-col-2 .order-xs-6-only {
    order: 6;
  }
  .cb-print.cb-print-col-2 .order-xs-7-only {
    order: 7;
  }
  .cb-print.cb-print-col-2 .order-xs-8-only {
    order: 8;
  }
  .cb-print.cb-print-col-2 .order-xs-9-only {
    order: 9;
  }
  .cb-print.cb-print-col-2 .order-xs-10-only {
    order: 10;
  }
  .cb-print.cb-print-col-2 .order-xs-11-only {
    order: 11;
  }
  .cb-print.cb-print-col-2 .order-xs-12-only {
    order: 12;
  }
}
.cb-print.cb-print-col-2 .col-sm-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-2 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-3 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-4 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-5 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-6 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-7 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-8 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-9 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-10 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-11 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm-12 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-sm,
.cb-print.cb-print-col-2 .col-sm-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 1px) {
  .cb-print.cb-print-col-2 .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cb-print.cb-print-col-2 .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cb-print.cb-print-col-2 .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cb-print.cb-print-col-2 .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .order-sm-first {
    order: -1;
  }
  .cb-print.cb-print-col-2 .order-sm-last {
    order: 13;
  }
  .cb-print.cb-print-col-2 .order-sm-0 {
    order: 0;
  }
  .cb-print.cb-print-col-2 .order-sm-1 {
    order: 1;
  }
  .cb-print.cb-print-col-2 .order-sm-2 {
    order: 2;
  }
  .cb-print.cb-print-col-2 .order-sm-3 {
    order: 3;
  }
  .cb-print.cb-print-col-2 .order-sm-4 {
    order: 4;
  }
  .cb-print.cb-print-col-2 .order-sm-5 {
    order: 5;
  }
  .cb-print.cb-print-col-2 .order-sm-6 {
    order: 6;
  }
  .cb-print.cb-print-col-2 .order-sm-7 {
    order: 7;
  }
  .cb-print.cb-print-col-2 .order-sm-8 {
    order: 8;
  }
  .cb-print.cb-print-col-2 .order-sm-9 {
    order: 9;
  }
  .cb-print.cb-print-col-2 .order-sm-10 {
    order: 10;
  }
  .cb-print.cb-print-col-2 .order-sm-11 {
    order: 11;
  }
  .cb-print.cb-print-col-2 .order-sm-12 {
    order: 12;
  }
  .cb-print.cb-print-col-2 .offset-sm-0 {
    margin-left: 0;
  }
  .cb-print.cb-print-col-2 .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-sm-3 {
    margin-left: 25%;
  }
  .cb-print.cb-print-col-2 .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-sm-6 {
    margin-left: 50%;
  }
  .cb-print.cb-print-col-2 .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-sm-9 {
    margin-left: 75%;
  }
  .cb-print.cb-print-col-2 .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1px) and (max-width: 0.98px) {
  .cb-print.cb-print-col-2 .order-sm-first-only {
    order: -1;
  }
  .cb-print.cb-print-col-2 .order-sm-last-only {
    order: 13;
  }
  .cb-print.cb-print-col-2 .order-sm-0-only {
    order: 0;
  }
  .cb-print.cb-print-col-2 .order-sm-1-only {
    order: 1;
  }
  .cb-print.cb-print-col-2 .order-sm-2-only {
    order: 2;
  }
  .cb-print.cb-print-col-2 .order-sm-3-only {
    order: 3;
  }
  .cb-print.cb-print-col-2 .order-sm-4-only {
    order: 4;
  }
  .cb-print.cb-print-col-2 .order-sm-5-only {
    order: 5;
  }
  .cb-print.cb-print-col-2 .order-sm-6-only {
    order: 6;
  }
  .cb-print.cb-print-col-2 .order-sm-7-only {
    order: 7;
  }
  .cb-print.cb-print-col-2 .order-sm-8-only {
    order: 8;
  }
  .cb-print.cb-print-col-2 .order-sm-9-only {
    order: 9;
  }
  .cb-print.cb-print-col-2 .order-sm-10-only {
    order: 10;
  }
  .cb-print.cb-print-col-2 .order-sm-11-only {
    order: 11;
  }
  .cb-print.cb-print-col-2 .order-sm-12-only {
    order: 12;
  }
}
.cb-print.cb-print-col-2 .col-md-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-2 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-3 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-5 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-6 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-7 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-8 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-9 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-10 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-11 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md-12 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.cb-print.cb-print-col-2 .col-md,
.cb-print.cb-print-col-2 .col-md-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 1px) {
  .cb-print.cb-print-col-2 .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cb-print.cb-print-col-2 .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cb-print.cb-print-col-2 .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cb-print.cb-print-col-2 .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cb-print.cb-print-col-2 .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cb-print.cb-print-col-2 .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cb-print.cb-print-col-2 .order-md-first {
    order: -1;
  }
  .cb-print.cb-print-col-2 .order-md-last {
    order: 13;
  }
  .cb-print.cb-print-col-2 .order-md-0 {
    order: 0;
  }
  .cb-print.cb-print-col-2 .order-md-1 {
    order: 1;
  }
  .cb-print.cb-print-col-2 .order-md-2 {
    order: 2;
  }
  .cb-print.cb-print-col-2 .order-md-3 {
    order: 3;
  }
  .cb-print.cb-print-col-2 .order-md-4 {
    order: 4;
  }
  .cb-print.cb-print-col-2 .order-md-5 {
    order: 5;
  }
  .cb-print.cb-print-col-2 .order-md-6 {
    order: 6;
  }
  .cb-print.cb-print-col-2 .order-md-7 {
    order: 7;
  }
  .cb-print.cb-print-col-2 .order-md-8 {
    order: 8;
  }
  .cb-print.cb-print-col-2 .order-md-9 {
    order: 9;
  }
  .cb-print.cb-print-col-2 .order-md-10 {
    order: 10;
  }
  .cb-print.cb-print-col-2 .order-md-11 {
    order: 11;
  }
  .cb-print.cb-print-col-2 .order-md-12 {
    order: 12;
  }
  .cb-print.cb-print-col-2 .offset-md-0 {
    margin-left: 0;
  }
  .cb-print.cb-print-col-2 .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-md-3 {
    margin-left: 25%;
  }
  .cb-print.cb-print-col-2 .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-md-6 {
    margin-left: 50%;
  }
  .cb-print.cb-print-col-2 .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .cb-print.cb-print-col-2 .offset-md-9 {
    margin-left: 75%;
  }
  .cb-print.cb-print-col-2 .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .cb-print.cb-print-col-2 .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1px) {
  .cb-print.cb-print-col-2 .order-md-first-only {
    order: -1;
  }
  .cb-print.cb-print-col-2 .order-md-last-only {
    order: 13;
  }
  .cb-print.cb-print-col-2 .order-md-0-only {
    order: 0;
  }
  .cb-print.cb-print-col-2 .order-md-1-only {
    order: 1;
  }
  .cb-print.cb-print-col-2 .order-md-2-only {
    order: 2;
  }
  .cb-print.cb-print-col-2 .order-md-3-only {
    order: 3;
  }
  .cb-print.cb-print-col-2 .order-md-4-only {
    order: 4;
  }
  .cb-print.cb-print-col-2 .order-md-5-only {
    order: 5;
  }
  .cb-print.cb-print-col-2 .order-md-6-only {
    order: 6;
  }
  .cb-print.cb-print-col-2 .order-md-7-only {
    order: 7;
  }
  .cb-print.cb-print-col-2 .order-md-8-only {
    order: 8;
  }
  .cb-print.cb-print-col-2 .order-md-9-only {
    order: 9;
  }
  .cb-print.cb-print-col-2 .order-md-10-only {
    order: 10;
  }
  .cb-print.cb-print-col-2 .order-md-11-only {
    order: 11;
  }
  .cb-print.cb-print-col-2 .order-md-12-only {
    order: 12;
  }
}
.cb-print.cb-print-col-2 .cb-print-all {
  display: block !important;
}