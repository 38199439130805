.cb-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.5em;
}
.cb-card a:not(.cb-btn) {
  color: #1e1e1e;
}
.cb-card a:not(.cb-btn):hover, .cb-card a:not(.cb-btn).cb-hover, .cb-card a:not(.cb-btn):focus, .cb-card a:not(.cb-btn).cb-focus {
  color: #1e1e1e;
}
.cb-card a:not(.cb-btn):active, .cb-card a:not(.cb-btn).cb-active {
  color: #505050;
}
.cb-card a:not(.cb-btn):visited, .cb-card a:not(.cb-btn).cb-visited {
  color: #1e1e1e;
}
.cb-card a:not(.cb-btn).cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-card a:not(.cb-btn).cb-disabled:hover, .cb-card a:not(.cb-btn).cb-disabled.cb-hover, .cb-card a:not(.cb-btn).cb-disabled:focus, .cb-card a:not(.cb-btn).cb-disabled.cb-focus, .cb-card a:not(.cb-btn).cb-disabled.cb-active, .cb-card a:not(.cb-btn).cb-disabled:visited, .cb-card a:not(.cb-btn).cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-card.cb-white-color a {
  color: #ffffff !important;
}
.cb-card.cb-white-color a:hover, .cb-card.cb-white-color a.cb-hover, .cb-card.cb-white-color a:focus, .cb-card.cb-white-color a.cb-focus {
  color: #ffffff;
}
.cb-card.cb-white-color a:active, .cb-card.cb-white-color a.cb-active {
  color: #ffffff;
}
.cb-card.cb-white-color a:visited, .cb-card.cb-white-color a.cb-visited {
  color: #ffffff;
}
.cb-card.cb-white-color a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-card.cb-white-color a.cb-disabled:hover, .cb-card.cb-white-color a.cb-disabled.cb-hover, .cb-card.cb-white-color a.cb-disabled:focus, .cb-card.cb-white-color a.cb-disabled.cb-focus, .cb-card.cb-white-color a.cb-disabled.cb-active, .cb-card.cb-white-color a.cb-disabled:visited, .cb-card.cb-white-color a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-card:not(.cb-card-link) {
  padding: 24px;
}
.cb-card:not(.cb-card-link) .cb-text-icon-overline .cb-glyph.cb-glyph-circular::after {
  background-color: #505050;
}
.cb-card:not(.cb-card-link) .cb-text-icon-overline .cb-icon.cb-icon-circular::after {
  background-color: #505050;
}
.cb-card .cb-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.cb-card .cb-card-title {
  font-size: 1.3125rem;
  line-height: 1.5238095238em;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 8px;
  color: #1e1e1e;
}
.cb-card .cb-card-title + .cb-card-label {
  margin-top: 8px;
}
.cb-card .cb-card-desc {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 300;
  color: #1e1e1e;
}
.cb-card .cb-card-desc + .cb-card-label {
  margin-top: 16px;
}
.cb-card .cb-date-time-tags * {
  color: #1e1e1e;
}
.cb-card .cb-card-title-large {
  font-family: Roboto !important;
  font-weight: 900;
  font-size: 2rem;
  line-height: 1.125em;
  color: #1e1e1e;
}
.cb-card .cb-card-footer {
  margin-top: auto;
}
.cb-card .cb-card-footer .cb-btn:first-child:nth-last-child(2):first-of-type,
.cb-card .cb-card-footer .cb-btn:first-child:nth-last-child(2) ~ .cb-btn:first-of-type,
.cb-card .cb-card-footer .cb-btn:first-child:nth-last-child(2):last-of-type,
.cb-card .cb-card-footer .cb-btn:first-child:nth-last-child(2) ~ .cb-btn:last-of-type {
  width: calc(50% - 16px);
  min-width: min-content;
}
.cb-card .cb-card-action {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: flex-end;
  margin-top: 24px;
}
.cb-card .cb-card-action .cb-glyph,
.cb-card .cb-card-action .cb-icon {
  align-self: flex-end;
  line-height: 24px;
}
.cb-card .cb-card-action .cb-meta-tags {
  min-width: fit-content;
}
.cb-card .cb-card-action .cb-meta-tags .cb-glyph,
.cb-card .cb-card-action .cb-meta-tags .cb-icon {
  align-self: auto;
}
.cb-card .cb-card-action span:first-child:last-child {
  margin-left: auto;
}
.cb-card .cb-card-media-content {
  min-height: 196px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;
  position: relative;
}
.cb-card .cb-card-media-content .cb-card-media-img {
  position: absolute;
  z-index: 1;
  height: 196px;
  width: 100%;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
}
.cb-card.cb-card-link > a {
  padding: 24px;
  text-decoration: none;
}
.cb-card.cb-card-link > a:hover, .cb-card.cb-card-link > a:focus {
  text-decoration: none;
}
.cb-card.cb-card-link > a:hover .cb-card-title,
.cb-card.cb-card-link > a:hover .cb-card-title-large, .cb-card.cb-card-link > a:focus .cb-card-title,
.cb-card.cb-card-link > a:focus .cb-card-title-large {
  text-decoration: underline;
}
.cb-card.cb-card-link > a:hover h1, .cb-card.cb-card-link > a:focus h1 {
  text-decoration: underline;
}
.cb-card.cb-card-link > a:hover h2, .cb-card.cb-card-link > a:focus h2 {
  text-decoration: underline;
}
.cb-card.cb-card-link > a:hover h3, .cb-card.cb-card-link > a:focus h3 {
  text-decoration: underline;
}
.cb-card.cb-card-link > a:hover h4, .cb-card.cb-card-link > a:focus h4 {
  text-decoration: underline;
}
.cb-card.cb-card-link > a:hover h5, .cb-card.cb-card-link > a:focus h5 {
  text-decoration: underline;
}
.cb-card.cb-card-link > a:hover h6, .cb-card.cb-card-link > a:focus h6 {
  text-decoration: underline;
}
.cb-card.cb-card-title-link {
  position: relative;
}
.cb-card.cb-card-title-link a {
  text-decoration: none;
}
.cb-card.cb-card-title-link a:hover, .cb-card.cb-card-title-link a:focus {
  text-decoration: underline;
}
.cb-card.cb-card-title-link a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.cb-card.cb-card-title-link.cb-card-media a:after, .cb-card.cb-card-title-link.cb-card-document a:after {
  z-index: 3;
}
.cb-card.cb-card-event.cb-super-event .cb-card-header {
  flex-wrap: wrap;
  margin-left: -24px;
  margin-top: -16px;
}
.cb-card.cb-card-event.cb-super-event .cb-card-header .cb-date-time-tags {
  margin-top: 16px;
  margin-left: 24px;
}
.cb-card.cb-card-event.cb-super-event .cb-card-header .cb-super-event-name {
  margin-top: 16px;
  margin-left: 24px;
  padding: 0 8px;
  box-shadow: 0 0 1px 1px #b2b2b2 inset;
  color: #1e1e1e;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  white-space: nowrap;
}
.cb-card.cb-card-event .cb-card-content .cb-card-action {
  flex-wrap: wrap;
}
.cb-card.cb-card-event .cb-card-content .cb-card-action span:not(.cb-text-super-event):first-child:last-child {
  margin-left: auto;
}
.cb-card.cb-card-event .cb-card-content .cb-card-action .cb-text-super-event {
  margin-top: 24px;
  margin-right: 24px;
}
.cb-card.cb-card-event .cb-card-content .cb-card-action .cb-btn.cb-btn-primary:first-child:last-child {
  margin-left: auto;
}
.cb-card.cb-card-cta {
  padding-top: 48px;
  padding-bottom: 48px;
}
.cb-card.cb-card-cta .cb-card-header .cb-glyph-content {
  color: #1e1e1e;
  font-size: 2rem;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.08);
  width: 72px;
  height: 72px;
  position: relative;
}
.cb-card.cb-card-cta .cb-card-header .cb-glyph-content::before, .cb-card.cb-card-cta .cb-card-header .cb-glyph-content::after {
  top: 5px;
  left: 5px;
}
.cb-card.cb-card-cta .cb-card-action {
  margin-top: 24px;
}

@media (min-width: 1024px) {
  .cb-band.cb-band-hero-card .cb-card {
    padding: 32px;
  }
}
@media (min-width: 1344px) {
  .cb-band.cb-band-hero-card .cb-card {
    padding: 48px;
  }
}
.cb-band.cb-band-hero-card .cb-card .cb-card-action {
  align-items: center;
}
.cb-band.cb-band-hero-card .cb-card .cb-card-action .cb-btn-row {
  margin-top: -16px;
}

[class*=cb-card-list-horizontal],
.cb-card-list-horizontal-24 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
  margin-top: -24px;
  width: calc(100% + 24px);
}
@media (max-width: 767.98px) {
  [class*=cb-card-list-horizontal],
  .cb-card-list-horizontal-24 {
    flex-direction: column;
  }
}
[class*=cb-card-list-horizontal] .cb-card,
.cb-card-list-horizontal-24 .cb-card {
  margin-top: 24px;
  margin-left: 24px;
  max-width: calc(25% - 24px);
  min-width: 336px;
  width: 100%;
}
[class*=cb-card-list-horizontal] .cb-card .cb-card-content,
.cb-card-list-horizontal-24 .cb-card .cb-card-content {
  flex: 1 1 auto !important;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023.98px) {
  [class*=cb-card-list-horizontal] .cb-card,
  .cb-card-list-horizontal-24 .cb-card {
    max-width: calc(50% - 24px);
    min-width: auto;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  [class*=cb-card-list-horizontal] .cb-card,
  .cb-card-list-horizontal-24 .cb-card {
    width: 100%;
    max-width: calc(100% - 24px);
  }
}
[class*=cb-card-list-horizontal] .cb-card > a,
.cb-card-list-horizontal-24 .cb-card > a {
  height: 100%;
  display: flex;
  flex-direction: column;
}
[class*=cb-card-list-horizontal] .cb-card .cb-card-content,
.cb-card-list-horizontal-24 .cb-card .cb-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
[class*=cb-card-list-horizontal] .cb-card .cb-card-content .cb-card-action,
.cb-card-list-horizontal-24 .cb-card .cb-card-content .cb-card-action {
  flex: 1;
  align-items: flex-end;
}
[class*=cb-card-list-horizontal] .cb-card .cb-card-content .cb-card-action .cb-glyph,
[class*=cb-card-list-horizontal] .cb-card .cb-card-content .cb-card-action .cb-icon,
.cb-card-list-horizontal-24 .cb-card .cb-card-content .cb-card-action .cb-glyph,
.cb-card-list-horizontal-24 .cb-card .cb-card-content .cb-card-action .cb-icon {
  line-height: 24px;
}
[class*=cb-card-list-horizontal].cb-box-shadow,
.cb-card-list-horizontal-24.cb-box-shadow {
  box-shadow: none;
}
[class*=cb-card-list-horizontal].cb-box-shadow .cb-card,
.cb-card-list-horizontal-24.cb-box-shadow .cb-card {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
}

.cb-card-list-horizontal-32 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
  margin-top: -32px;
  width: calc(100% + 32px);
}
.cb-card-list-horizontal-32 .cb-card {
  margin-top: 32px;
  margin-left: 32px;
  max-width: calc(33% - 32px);
  min-width: 288px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-card-list-horizontal-32 .cb-card {
    max-width: calc(50% - 32px);
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-card-list-horizontal-32 .cb-card {
    width: 100%;
    max-width: calc(100% - 32px);
  }
}

.cb-card-list-horizontal-48 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -48px;
  margin-top: -48px;
  width: calc(100% + 48px);
}
.cb-card-list-horizontal-48 .cb-card {
  margin-top: 48px;
  margin-left: 48px;
  max-width: calc(33% - 48px);
  min-width: 276px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-card-list-horizontal-48 .cb-card {
    max-width: calc(50% - 48px);
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-card-list-horizontal-48 .cb-card {
    width: 100%;
    max-width: calc(100% - 48px);
  }
}

.cb-card-list-vertical .cb-card {
  border: 0;
  border-top: 1px solid #d9d9d9;
  box-shadow: none;
  border-radius: 0;
  text-align: left;
  max-width: 100%;
}
.cb-card-list-vertical .cb-card .cb-card-header {
  margin-bottom: 8px;
}

@media (max-width: 767.98px) {
  .cb-card-list-horizontal-2,
  .cb-card-list-horizontal-elective {
    margin-left: -24px;
  }
}
.cb-card-list-horizontal-2 .cb-card,
.cb-card-list-horizontal-elective .cb-card {
  width: calc(50% - 24px);
  max-width: calc(50% - 24px);
  min-width: auto;
}
@media (max-width: 767.98px) {
  .cb-card-list-horizontal-2 .cb-card,
  .cb-card-list-horizontal-elective .cb-card {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    width: calc(100% + 24px);
    min-width: calc(100% + 24px);
  }
}

.cb-card-custom-col .cb-card .cb-card-content {
  flex: 1 1 auto !important;
  display: flex;
  flex-direction: column;
}
.cb-card-custom-col .cb-card.cb-card-link > a {
  height: 100%;
  display: flex;
  flex-direction: column;
}

[class*=safari] .cb-date-time-tags p {
  line-height: 20px !important;
  padding-top: 4px !important;
}
[class*=safari] .cb-date-time-tags p:first-of-type::after {
  top: 0 !important;
}

.cb-card.cb-identity-card {
  align-items: center;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}
.cb-card.cb-identity-card .cb-identity-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  min-width: 375px;
}
.cb-card.cb-identity-card .cb-identity-card-container p {
  width: 264px;
}
.cb-card.cb-identity-card .cb-identity-card-container .cb-btn-primary {
  width: 200px;
  color: #fff;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-card.cb-identity-card .cb-identity-card-container {
    width: auto;
    min-width: auto;
  }
}
@media (max-width: 380px) {
  .cb-card.cb-identity-card .cb-identity-card-container p,
  .cb-card.cb-identity-card .cb-identity-card-container .cb-btn-primary {
    width: auto;
  }
}

[data-cb-input-m=keyboard] .cb-card-title-link a:focus {
  box-shadow: none;
  outline: none;
}
[data-cb-input-m=keyboard] .cb-card-title-link a:focus::after {
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px rgb(0, 95, 204);
}