.cb-filter:not(.cb-glyph):not(.cb-icon) {
  min-width: 400px;
}
.cb-filter:not(.cb-glyph):not(.cb-icon) .cb-popover-inner {
  padding-bottom: 16px;
}
.cb-filter:not(.cb-glyph):not(.cb-icon).cb-popover-content-adjust {
  max-height: calc(100vh - 96px);
  overflow: hidden;
}
.cb-filter:not(.cb-glyph):not(.cb-icon).cb-popover-content-adjust .cb-popover-content {
  overflow-x: hidden;
  padding: 2px 2px 0 2px;
}