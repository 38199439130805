.cb-back-to-top {
  display: none;
  position: fixed;
  bottom: 24px;
  right: 24px;
}
.cb-back-to-top.cb-back-to-top-show {
  display: block;
}
.cb-back-to-top.cb-back-to-top-hide {
  display: none;
}