@font-face {
  font-family: "CB Icons";
  font-style: normal;
  src: url(https://atlas.collegeboard.org/apricot/prod/4.5.14/fonts/cb-icons-4_5_14.woff) format("woff"), url(https://atlas.collegeboard.org/apricot/prod/4.5.14/fonts/cb-icons-4_5_14.ttf) format("truetype");
  font-display: fallback;
}
.cb-icon {
  font-family: CB Icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cb-icon.cb-accessibility::before {
  content: "\e900";
}
.cb-icon.cb-acorn::before {
  content: "\e901";
}
.cb-icon.cb-apple::before {
  content: "\e902";
}
.cb-icon.cb-award-medal::before {
  content: "\e903";
}
.cb-icon.cb-book-open::before {
  content: "\e904";
}
.cb-icon.cb-book::before {
  content: "\e905";
}
.cb-icon.cb-bullet-list::before {
  content: "\e906";
}
.cb-icon.cb-calculator::before {
  content: "\e907";
}
.cb-icon.cb-calendar-add::before {
  content: "\e908";
}
.cb-icon.cb-calendar-save-the-date::before {
  content: "\e909";
}
.cb-icon.cb-cart::before {
  content: "\e981";
}
.cb-icon.cb-checkbox::before {
  content: "\e90a";
}
.cb-icon.cb-checklist::before {
  content: "\e90b";
}
.cb-icon.cb-clipboard-check::before {
  content: "\e90c";
}
.cb-icon.cb-clipboard::before {
  content: "\e90d";
}
.cb-icon.cb-coin::before {
  content: "\e90e";
}
.cb-icon.cb-document-award::before {
  content: "\e90f";
}
.cb-icon.cb-dollar::before {
  content: "\e910";
}
.cb-icon.cb-download-alt::before {
  content: "\e911";
}
.cb-icon.cb-expand-window::before {
  content: "\e912";
}
.cb-icon.cb-explore::before {
  content: "\e913";
}
.cb-icon.cb-face-bad::before {
  content: "\e914";
}
.cb-icon.cb-face-excellent::before {
  content: "\e915";
}
.cb-icon.cb-face-good::before {
  content: "\e916";
}
.cb-icon.cb-face-neutral::before {
  content: "\e917";
}
.cb-icon.cb-face-very-bad::before {
  content: "\e918";
}
.cb-icon.cb-feedback::before {
  content: "\e919";
}
.cb-icon.cb-financial-aid::before {
  content: "\e91a";
}
.cb-icon.cb-globe::before {
  content: "\e91b";
}
.cb-icon.cb-graduate::before {
  content: "\e91c";
}
.cb-icon.cb-graph::before {
  content: "\e91d";
}
.cb-icon.cb-home::before {
  content: "\e91e";
}
.cb-icon.cb-image::before {
  content: "\e91f";
}
.cb-icon.cb-info-page::before {
  content: "\e920";
}
.cb-icon.cb-info::before {
  content: "\e921";
}
.cb-icon.cb-khan-academy::before {
  content: "\e922";
}
.cb-icon.cb-laptop-exclamation::before {
  content: "\e923";
}
.cb-icon.cb-lightbulb::before {
  content: "\e924";
}
.cb-icon.cb-list::before {
  content: "\e925";
}
.cb-icon.cb-math-essential::before {
  content: "\e926";
}
.cb-icon.cb-microscope::before {
  content: "\e927";
}
.cb-icon.cb-new-page::before {
  content: "\e928";
}
.cb-icon.cb-new-window::before {
  content: "\e929";
}
.cb-icon.cb-note::before {
  content: "\e92a";
}
.cb-icon.cb-notepad-check::before {
  content: "\e92b";
}
.cb-icon.cb-pages::before {
  content: "\e92c";
}
.cb-icon.cb-paper-airplane::before {
  content: "\e92d";
}
.cb-icon.cb-practice-test::before {
  content: "\e92e";
}
.cb-icon.cb-practice::before {
  content: "\e92f";
}
.cb-icon.cb-quotes::before {
  content: "\e930";
}
.cb-icon.cb-raised-pencil::before {
  content: "\e931";
}
.cb-icon.cb-school-urban::before {
  content: "\e932";
}
.cb-icon.cb-school::before {
  content: "\e933";
}
.cb-icon.cb-send-scores::before {
  content: "\e934";
}
.cb-icon.cb-share-alt::before {
  content: "\e935";
}
.cb-icon.cb-stopwatch::before {
  content: "\e936";
}
.cb-icon.cb-submit::before {
  content: "\e937";
}
.cb-icon.cb-tablet::before {
  content: "\e938";
}
.cb-icon.cb-task-complete::before {
  content: "\e939";
}
.cb-icon.cb-teacher::before {
  content: "\e93a";
}
.cb-icon.cb-team-leader::before {
  content: "\e93b";
}
.cb-icon.cb-trophy::before {
  content: "\e93c";
}
.cb-icon.cb-twitter::before {
  content: "\e93d";
}
.cb-icon.cb-university::before {
  content: "\e93e";
}
.cb-icon.cb-upload::before {
  content: "\e93f";
}
.cb-icon.cb-cal-empty::before {
  content: "\e940";
}
.cb-icon.cb-cal-full::before {
  content: "\e941";
}
.cb-icon.cb-compose::before {
  content: "\e942";
}
.cb-icon.cb-heart::before {
  content: "\e943";
}
.cb-icon.cb-hyperlink::before {
  content: "\e944";
}
.cb-icon.cb-minus::before {
  content: "\e945";
}
.cb-icon.cb-pending::before {
  content: "\e946";
}
.cb-icon.cb-phone::before {
  content: "\e947";
}
.cb-icon.cb-plus::before {
  content: "\e948";
}
.cb-icon.cb-settings::before {
  content: "\e949";
}
.cb-icon.cb-tool::before {
  content: "\e94a";
}
.cb-icon.cb-user-add::before {
  content: "\e94b";
}
.cb-icon.cb-window::before {
  content: "\e94c";
}
.cb-icon.cb-attachment::before {
  content: "\e94d";
}
.cb-icon.cb-box::before {
  content: "\e94e";
}
.cb-icon.cb-lg-left::before {
  content: "\e94f";
}
.cb-icon.cb-lg-right::before {
  content: "\e950";
}
.cb-icon.cb-check-circle::before {
  content: "\e951";
}
.cb-icon.cb-exclamation-circle::before {
  content: "\e952";
}
.cb-icon.cb-check-fill::before {
  content: "\e951";
}
.cb-icon.cb-exclamation-fill::before {
  content: "\e952";
}
.cb-icon.cb-check::before {
  content: "\e953";
}
.cb-icon.cb-circle::before {
  content: "\e954";
}
.cb-icon.cb-box-fill::before {
  content: "\e955";
}
.cb-icon.cb-compose-cell::before {
  content: "\e956";
}
.cb-icon.cb-compose-pen::before {
  content: "\e957";
}
.cb-icon.cb-computer::before {
  content: "\e958";
}
.cb-icon.cb-disc-alt::before {
  content: "\e959";
}
.cb-icon.cb-disc::before {
  content: "\e95a";
}
.cb-icon.cb-down::before {
  content: "\e95b";
}
.cb-icon.cb-download::before {
  content: "\e95c";
}
.cb-icon.cb-east::before {
  content: "\e95d";
}
.cb-icon.cb-error::before {
  content: "\e95e";
}
.cb-icon.cb-location-tag::before {
  content: "\e95f";
}
.cb-icon.cb-order-asc::before {
  content: "\e960";
}
.cb-icon.cb-pause::before {
  content: "\e961";
}
.cb-icon.cb-exclamation::before {
  content: "\e962";
}
.cb-icon.cb-exit-full-screen::before {
  content: "\e963";
}
.cb-icon.cb-facebook::before {
  content: "\e964";
}
.cb-icon.cb-filter::before {
  content: "\e965";
}
.cb-icon.cb-full-screen::before {
  content: "\e966";
}
.cb-icon.cb-instagram::before {
  content: "\e968";
}
.cb-icon.cb-laptop::before {
  content: "\e969";
}
.cb-icon.cb-left::before {
  content: "\e96a";
}
.cb-icon.cb-linkedin::before {
  content: "\e96b";
}
.cb-icon.cb-location-pin::before {
  content: "\e96c";
}
.cb-icon.cb-order-desc::before {
  content: "\e96d";
}
.cb-icon.cb-location::before {
  content: "\e96e";
}
.cb-icon.cb-lock-open::before {
  content: "\e96f";
}
.cb-icon.cb-lock::before {
  content: "\e970";
}
.cb-icon.cb-mail::before {
  content: "\e971";
}
.cb-icon.cb-megaphone::before {
  content: "\e972";
}
.cb-icon.cb-menu::before {
  content: "\e973";
}
.cb-icon.cb-north-east::before {
  content: "\e975";
}
.cb-icon.cb-north-west::before {
  content: "\e976";
}
.cb-icon.cb-north::before {
  content: "\e977";
}
.cb-icon.cb-chat::before {
  content: "\e978";
}
.cb-icon.cb-bell::before {
  content: "\e979";
}
.cb-icon.cb-copy::before {
  content: "\e97a";
}
.cb-icon.cb-folder::before {
  content: "\e97b";
}
.cb-icon.cb-carousel-backward-sm::before {
  content: "\e97c";
}
.cb-icon.cb-carousel-forward-sm::before {
  content: "\e97e";
}
.cb-icon.cb-carousel-backward::before {
  content: "\e97f";
}
.cb-icon.cb-carousel-forward::before {
  content: "\e980";
}
.cb-icon.cb-gallery-backward::before {
  content: "\e982";
}
.cb-icon.cb-gallery-forward::before {
  content: "\e983";
}
.cb-icon.cb-play::before {
  content: "\e984";
}
.cb-icon.cb-play-video::before {
  content: "\e984";
}
.cb-icon.cb-print::before {
  content: "\e986";
}
.cb-icon.cb-question::before {
  content: "\e987";
}
.cb-icon.cb-refresh::before {
  content: "\e988";
}
.cb-icon.cb-right::before {
  content: "\e989";
}
.cb-icon.cb-search::before {
  content: "\e98a";
}
.cb-icon.cb-see-off::before {
  content: "\e98b";
}
.cb-icon.cb-see-on::before {
  content: "\e98c";
}
.cb-icon.cb-share-arrow::before {
  content: "\e98e";
}
.cb-icon.cb-share::before {
  content: "\e98f";
}
.cb-icon.cb-sort-asc::before {
  content: "\e990";
}
.cb-icon.cb-sort-desc::before {
  content: "\e991";
}
.cb-icon.cb-sort::before {
  content: "\e992";
}
.cb-icon.cb-south::before {
  content: "\e993";
}
.cb-icon.cb-tag::before {
  content: "\e994";
}
.cb-icon.cb-up::before {
  content: "\e995";
}
.cb-icon.cb-user-circle::before {
  content: "\e996";
}
.cb-icon.cb-user::before {
  content: "\e997";
}
.cb-icon.cb-west::before {
  content: "\e998";
}
.cb-icon.cb-x-mark::before {
  content: "\e999";
}
.cb-icon.cb-youtube::before {
  content: "\e99a";
}
.cb-icon.cb-more::before {
  content: "\e967";
}
.cb-icon.cb-more-alt::before {
  content: "\e974";
}
.cb-icon.cb-question-circle::before {
  content: "\e97d";
}
.cb-icon.cb-search {
  background: none;
}
.cb-icon.cb-acorn::before {
  left: 0.3em;
}

.cb-icon-numeric {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: inline-block;
  border-radius: 50%;
  font-size: 1rem;
  width: 2em;
  height: 2em;
  font-weight: 700;
  line-height: 1.82em !important;
  text-align: center;
  border: 0.125em solid #1e1e1e;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .cb-icon-numeric {
      line-height: 2em !important;
    }
  }
}
.cb-icon-numeric::before {
  content: attr(data-cb-number);
}
.cb-icon-numeric.cb-icon-circular {
  color: #ffffff;
  border-color: #324dc7;
  background-color: #324dc7;
}

.cb-icon-circular:not(.cb-icon-numeric) {
  position: relative;
  display: block;
  height: 2em;
  width: 2em;
}
.cb-icon-circular:not(.cb-icon-numeric)::before {
  position: absolute;
  z-index: 2;
  left: 0.5em;
  top: 0.5em;
  color: #ffffff;
}
.cb-icon-circular:not(.cb-icon-numeric)::after {
  content: " ";
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background-color: #324dc7;
}

.cb-icon-avatar {
  transition: all 0.35s ease;
  position: relative;
  display: block;
  font-size: 1rem;
  width: 2em;
  height: 2em;
}
.cb-icon-avatar::before {
  position: absolute;
  z-index: 2;
  font-size: 1.3125em;
  left: 0.25em;
  top: 0.25em;
  color: #ffffff;
}
.cb-icon-avatar::after {
  content: " ";
  position: absolute;
  z-index: 1;
  display: block;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  background-color: #1e1e1e;
}
@media (min-width: 1024px) {
  .cb-icon-avatar {
    width: 3em;
    height: 3em;
  }
  .cb-icon-avatar::before {
    font-size: 2em;
  }
  .cb-icon-avatar::after {
    width: 3em;
    height: 3em;
  }
}

.cb-icon.cb-icon-12 {
  font-size: 12px;
}
.cb-icon.cb-icon-circular.cb-icon-24 {
  font-size: 12px;
}

.cb-icon.cb-icon-14 {
  font-size: 14px;
}
.cb-icon.cb-icon-circular.cb-icon-28 {
  font-size: 14px;
}

.cb-icon.cb-icon-16 {
  font-size: 16px;
}
.cb-icon.cb-icon-circular.cb-icon-32 {
  font-size: 16px;
}

.cb-icon.cb-icon-24 {
  font-size: 24px;
}
.cb-icon.cb-icon-circular.cb-icon-48 {
  font-size: 24px;
}

.cb-icon.cb-icon-32 {
  font-size: 32px;
}
.cb-icon.cb-icon-circular.cb-icon-64 {
  font-size: 32px;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-icon-xs-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-xs-24 {
    font-size: 12px !important;
  }
  .cb-icon-xs-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-xs-28 {
    font-size: 14px !important;
  }
  .cb-icon-xs-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-xs-32 {
    font-size: 16px !important;
  }
  .cb-icon-xs-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-xs-48 {
    font-size: 24px !important;
  }
  .cb-icon-xs-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-xs-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1px) {
  .cb-icon-xs-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-xs-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-xs-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-xs-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-xs-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-xs-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-xs-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-xs-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-xs-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-xs-up-64 {
    font-size: 32px !important;
  }
}
@media (max-width: 767.98px) {
  .cb-icon-xs-down-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-xs-down-24 {
    font-size: 12px !important;
  }
  .cb-icon-xs-down-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-xs-down-28 {
    font-size: 14px !important;
  }
  .cb-icon-xs-down-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-xs-down-32 {
    font-size: 16px !important;
  }
  .cb-icon-xs-down-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-xs-down-48 {
    font-size: 24px !important;
  }
  .cb-icon-xs-down-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-xs-down-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-icon-sm-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-sm-24 {
    font-size: 12px !important;
  }
  .cb-icon-sm-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-sm-28 {
    font-size: 14px !important;
  }
  .cb-icon-sm-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-sm-32 {
    font-size: 16px !important;
  }
  .cb-icon-sm-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-sm-48 {
    font-size: 24px !important;
  }
  .cb-icon-sm-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-sm-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 768px) {
  .cb-icon-sm-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-sm-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-sm-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-sm-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-sm-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-sm-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-sm-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-sm-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-sm-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-sm-up-64 {
    font-size: 32px !important;
  }
}
@media (max-width: 1023.98px) {
  .cb-icon-sm-down-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-sm-down-24 {
    font-size: 12px !important;
  }
  .cb-icon-sm-down-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-sm-down-28 {
    font-size: 14px !important;
  }
  .cb-icon-sm-down-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-sm-down-32 {
    font-size: 16px !important;
  }
  .cb-icon-sm-down-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-sm-down-48 {
    font-size: 24px !important;
  }
  .cb-icon-sm-down-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-sm-down-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-icon-md-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-md-24 {
    font-size: 12px !important;
  }
  .cb-icon-md-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-md-28 {
    font-size: 14px !important;
  }
  .cb-icon-md-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-md-32 {
    font-size: 16px !important;
  }
  .cb-icon-md-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-md-48 {
    font-size: 24px !important;
  }
  .cb-icon-md-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-md-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1024px) {
  .cb-icon-md-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-md-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-md-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-md-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-md-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-md-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-md-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-md-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-md-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-md-up-64 {
    font-size: 32px !important;
  }
}
@media (max-width: 1247.98px) {
  .cb-icon-md-down-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-md-down-24 {
    font-size: 12px !important;
  }
  .cb-icon-md-down-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-md-down-28 {
    font-size: 14px !important;
  }
  .cb-icon-md-down-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-md-down-32 {
    font-size: 16px !important;
  }
  .cb-icon-md-down-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-md-down-48 {
    font-size: 24px !important;
  }
  .cb-icon-md-down-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-md-down-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-icon-lg-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-lg-24 {
    font-size: 12px !important;
  }
  .cb-icon-lg-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-lg-28 {
    font-size: 14px !important;
  }
  .cb-icon-lg-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-lg-32 {
    font-size: 16px !important;
  }
  .cb-icon-lg-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-lg-48 {
    font-size: 24px !important;
  }
  .cb-icon-lg-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-lg-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1248px) {
  .cb-icon-lg-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-lg-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-lg-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-lg-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-lg-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-lg-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-lg-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-lg-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-lg-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-lg-up-64 {
    font-size: 32px !important;
  }
}
@media (max-width: 1343.98px) {
  .cb-icon-lg-down-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-lg-down-24 {
    font-size: 12px !important;
  }
  .cb-icon-lg-down-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-lg-down-28 {
    font-size: 14px !important;
  }
  .cb-icon-lg-down-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-lg-down-32 {
    font-size: 16px !important;
  }
  .cb-icon-lg-down-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-lg-down-48 {
    font-size: 24px !important;
  }
  .cb-icon-lg-down-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-lg-down-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-icon-xl-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-xl-24 {
    font-size: 12px !important;
  }
  .cb-icon-xl-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-xl-28 {
    font-size: 14px !important;
  }
  .cb-icon-xl-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-xl-32 {
    font-size: 16px !important;
  }
  .cb-icon-xl-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-xl-48 {
    font-size: 24px !important;
  }
  .cb-icon-xl-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-xl-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1344px) {
  .cb-icon-xl-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-xl-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-xl-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-xl-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-xl-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-xl-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-xl-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-xl-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-xl-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-xl-up-64 {
    font-size: 32px !important;
  }
}
@media (max-width: 1439.98px) {
  .cb-icon-xl-down-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-xl-down-24 {
    font-size: 12px !important;
  }
  .cb-icon-xl-down-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-xl-down-28 {
    font-size: 14px !important;
  }
  .cb-icon-xl-down-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-xl-down-32 {
    font-size: 16px !important;
  }
  .cb-icon-xl-down-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-xl-down-48 {
    font-size: 24px !important;
  }
  .cb-icon-xl-down-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-xl-down-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-icon-2xl-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-2xl-24 {
    font-size: 12px !important;
  }
  .cb-icon-2xl-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-2xl-28 {
    font-size: 14px !important;
  }
  .cb-icon-2xl-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-2xl-32 {
    font-size: 16px !important;
  }
  .cb-icon-2xl-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-2xl-48 {
    font-size: 24px !important;
  }
  .cb-icon-2xl-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-2xl-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1440px) {
  .cb-icon-2xl-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-2xl-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-2xl-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-2xl-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-2xl-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-2xl-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-2xl-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-2xl-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-2xl-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-2xl-up-64 {
    font-size: 32px !important;
  }
}
@media (max-width: 1727.98px) {
  .cb-icon-2xl-down-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-2xl-down-24 {
    font-size: 12px !important;
  }
  .cb-icon-2xl-down-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-2xl-down-28 {
    font-size: 14px !important;
  }
  .cb-icon-2xl-down-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-2xl-down-32 {
    font-size: 16px !important;
  }
  .cb-icon-2xl-down-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-2xl-down-48 {
    font-size: 24px !important;
  }
  .cb-icon-2xl-down-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-2xl-down-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1728px) {
  .cb-icon-3xl-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-3xl-24 {
    font-size: 12px !important;
  }
  .cb-icon-3xl-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-3xl-28 {
    font-size: 14px !important;
  }
  .cb-icon-3xl-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-3xl-32 {
    font-size: 16px !important;
  }
  .cb-icon-3xl-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-3xl-48 {
    font-size: 24px !important;
  }
  .cb-icon-3xl-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-3xl-64 {
    font-size: 32px !important;
  }
}
@media (min-width: 1728px) {
  .cb-icon-3xl-up-12 {
    font-size: 12px !important;
  }
  .cb-icon-circular.cb-icon-3xl-up-24 {
    font-size: 12px !important;
  }
  .cb-icon-3xl-up-14 {
    font-size: 14px !important;
  }
  .cb-icon-circular.cb-icon-3xl-up-28 {
    font-size: 14px !important;
  }
  .cb-icon-3xl-up-16 {
    font-size: 16px !important;
  }
  .cb-icon-circular.cb-icon-3xl-up-32 {
    font-size: 16px !important;
  }
  .cb-icon-3xl-up-24 {
    font-size: 24px !important;
  }
  .cb-icon-circular.cb-icon-3xl-up-48 {
    font-size: 24px !important;
  }
  .cb-icon-3xl-up-32 {
    font-size: 32px !important;
  }
  .cb-icon-circular.cb-icon-3xl-up-64 {
    font-size: 32px !important;
  }
}
.cb-icon-3xl-down-12 {
  font-size: 12px !important;
}

.cb-icon-circular.cb-icon-3xl-down-24 {
  font-size: 12px !important;
}

.cb-icon-3xl-down-14 {
  font-size: 14px !important;
}

.cb-icon-circular.cb-icon-3xl-down-28 {
  font-size: 14px !important;
}

.cb-icon-3xl-down-16 {
  font-size: 16px !important;
}

.cb-icon-circular.cb-icon-3xl-down-32 {
  font-size: 16px !important;
}

.cb-icon-3xl-down-24 {
  font-size: 24px !important;
}

.cb-icon-circular.cb-icon-3xl-down-48 {
  font-size: 24px !important;
}

.cb-icon-3xl-down-32 {
  font-size: 32px !important;
}

.cb-icon-circular.cb-icon-3xl-down-64 {
  font-size: 32px !important;
}

.cb-icon-numeric.cb-icon-24 {
  font-size: 12px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-24 {
  font-size: 12px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-24 {
  line-height: 23px !important;
}

.cb-icon-numeric.cb-icon-28 {
  font-size: 14px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-28 {
  font-size: 14px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-24 {
  line-height: 23px !important;
}

.cb-icon-numeric.cb-icon-32 {
  font-size: 16px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-32 {
  font-size: 16px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-24 {
  line-height: 23px !important;
}

.cb-icon-numeric.cb-icon-48 {
  font-size: 24px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-48 {
  font-size: 24px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-24 {
  line-height: 23px !important;
}

.cb-icon-numeric.cb-icon-64 {
  font-size: 32px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-64 {
  font-size: 32px;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-24 {
  line-height: 23px !important;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-icon-numeric.cb-icon-xs-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1px) {
  .cb-icon-numeric.cb-icon-xs-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 767.98px) {
  .cb-icon-numeric.cb-icon-xs-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xs-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xs-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-icon-numeric.cb-icon-sm-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 768px) {
  .cb-icon-numeric.cb-icon-sm-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1023.98px) {
  .cb-icon-numeric.cb-icon-sm-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-sm-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-sm-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-icon-numeric.cb-icon-md-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .cb-icon-numeric.cb-icon-md-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1247.98px) {
  .cb-icon-numeric.cb-icon-md-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-md-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-md-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-icon-numeric.cb-icon-lg-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1248px) {
  .cb-icon-numeric.cb-icon-lg-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1343.98px) {
  .cb-icon-numeric.cb-icon-lg-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-lg-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-lg-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-icon-numeric.cb-icon-xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1344px) {
  .cb-icon-numeric.cb-icon-xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1439.98px) {
  .cb-icon-numeric.cb-icon-xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-icon-numeric.cb-icon-2xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) {
  .cb-icon-numeric.cb-icon-2xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (max-width: 1727.98px) {
  .cb-icon-numeric.cb-icon-2xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-down-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-down-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-down-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-down-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-2xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-2xl-down-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1728px) {
  .cb-icon-numeric.cb-icon-3xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
@media (min-width: 1728px) {
  .cb-icon-numeric.cb-icon-3xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-up-24 {
    font-size: 12px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-up-28 {
    font-size: 14px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-up-32 {
    font-size: 16px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-up-48 {
    font-size: 24px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-3xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
  .cb-icon-numeric.cb-icon-circular.cb-icon-3xl-up-64 {
    font-size: 32px !important;
    flex-shrink: 0;
  }
}
.cb-icon-numeric.cb-icon-3xl-down-24 {
  font-size: 12px !important;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-3xl-down-24 {
  font-size: 12px !important;
  flex-shrink: 0;
}

.cb-icon-numeric.cb-icon-3xl-down-28 {
  font-size: 14px !important;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-3xl-down-28 {
  font-size: 14px !important;
  flex-shrink: 0;
}

.cb-icon-numeric.cb-icon-3xl-down-32 {
  font-size: 16px !important;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-3xl-down-32 {
  font-size: 16px !important;
  flex-shrink: 0;
}

.cb-icon-numeric.cb-icon-3xl-down-48 {
  font-size: 24px !important;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-3xl-down-48 {
  font-size: 24px !important;
  flex-shrink: 0;
}

.cb-icon-numeric.cb-icon-3xl-down-64 {
  font-size: 32px !important;
  flex-shrink: 0;
}
.cb-icon-numeric.cb-icon-circular.cb-icon-3xl-down-64 {
  font-size: 32px !important;
  flex-shrink: 0;
}

.cb-icon.cb-white-color.cb-icon-circular::after {
  background-color: #ffffff;
}

.cb-icon-numeric.cb-white-color {
  border-color: #ffffff;
}
.cb-icon-numeric.cb-white-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #ffffff;
  background-color: #ffffff;
}

.cb-icon.cb-black1-color.cb-icon-circular::after {
  background-color: #1e1e1e;
}

.cb-icon-numeric.cb-black1-color {
  border-color: #1e1e1e;
}
.cb-icon-numeric.cb-black1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #1e1e1e;
  background-color: #1e1e1e;
}

.cb-icon.cb-black2-color.cb-icon-circular::after {
  background-color: #151515;
}

.cb-icon-numeric.cb-black2-color {
  border-color: #151515;
}
.cb-icon-numeric.cb-black2-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #151515;
  background-color: #151515;
}

.cb-icon.cb-blue1-color.cb-icon-circular::after {
  background-color: #006298;
}

.cb-icon-numeric.cb-blue1-color {
  border-color: #006298;
}
.cb-icon-numeric.cb-blue1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #006298;
  background-color: #006298;
}

.cb-icon.cb-blue2-color.cb-icon-circular::after {
  background-color: #0077c8;
}

.cb-icon-numeric.cb-blue2-color {
  border-color: #0077c8;
}
.cb-icon-numeric.cb-blue2-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #0077c8;
  background-color: #0077c8;
}

.cb-icon.cb-blue3-color.cb-icon-circular::after {
  background-color: #009cde;
}

.cb-icon-numeric.cb-blue3-color {
  border-color: #009cde;
}
.cb-icon-numeric.cb-blue3-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #009cde;
  background-color: #009cde;
}

.cb-icon.cb-blue4-color.cb-icon-circular::after {
  background-color: #71c5e8;
}

.cb-icon-numeric.cb-blue4-color {
  border-color: #71c5e8;
}
.cb-icon-numeric.cb-blue4-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #71c5e8;
  background-color: #71c5e8;
}

.cb-icon.cb-blue5-color.cb-icon-circular::after {
  background-color: #324dc7;
}

.cb-icon-numeric.cb-blue5-color {
  border-color: #324dc7;
}
.cb-icon-numeric.cb-blue5-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #324dc7;
  background-color: #324dc7;
}

.cb-icon.cb-gray1-color.cb-icon-circular::after {
  background-color: #505050;
}

.cb-icon-numeric.cb-gray1-color {
  border-color: #505050;
}
.cb-icon-numeric.cb-gray1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #505050;
  background-color: #505050;
}

.cb-icon.cb-gray2-color.cb-icon-circular::after {
  background-color: #888888;
}

.cb-icon-numeric.cb-gray2-color {
  border-color: #888888;
}
.cb-icon-numeric.cb-gray2-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #888888;
  background-color: #888888;
}

.cb-icon.cb-gray3-color.cb-icon-circular::after {
  background-color: #b2b2b2;
}

.cb-icon-numeric.cb-gray3-color {
  border-color: #b2b2b2;
}
.cb-icon-numeric.cb-gray3-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #b2b2b2;
  background-color: #b2b2b2;
}

.cb-icon.cb-gray4-color.cb-icon-circular::after {
  background-color: #d9d9d9;
}

.cb-icon-numeric.cb-gray4-color {
  border-color: #d9d9d9;
}
.cb-icon-numeric.cb-gray4-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
}

.cb-icon.cb-gray5-color.cb-icon-circular::after {
  background-color: #f0f0f0;
}

.cb-icon-numeric.cb-gray5-color {
  border-color: #f0f0f0;
}
.cb-icon-numeric.cb-gray5-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #f0f0f0;
  background-color: #f0f0f0;
}

.cb-icon.cb-yellow1-color.cb-icon-circular::after {
  background-color: #fedb00;
}

.cb-icon-numeric.cb-yellow1-color {
  border-color: #fedb00;
}
.cb-icon-numeric.cb-yellow1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #fedb00;
  background-color: #fedb00;
}

.cb-icon.cb-orange1-color.cb-icon-circular::after {
  background-color: #e57200;
}

.cb-icon-numeric.cb-orange1-color {
  border-color: #e57200;
}
.cb-icon-numeric.cb-orange1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #e57200;
  background-color: #e57200;
}

.cb-icon.cb-red1-color.cb-icon-circular::after {
  background-color: #ab2334;
}

.cb-icon-numeric.cb-red1-color {
  border-color: #ab2334;
}
.cb-icon-numeric.cb-red1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #ab2334;
  background-color: #ab2334;
}

.cb-icon.cb-purple1-color.cb-icon-circular::after {
  background-color: #702f8a;
}

.cb-icon-numeric.cb-purple1-color {
  border-color: #702f8a;
}
.cb-icon-numeric.cb-purple1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #702f8a;
  background-color: #702f8a;
}

.cb-icon.cb-purple2-color.cb-icon-circular::after {
  background-color: #a05eb5;
}

.cb-icon-numeric.cb-purple2-color {
  border-color: #a05eb5;
}
.cb-icon-numeric.cb-purple2-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #a05eb5;
  background-color: #a05eb5;
}

.cb-icon.cb-green1-color.cb-icon-circular::after {
  background-color: #3a913f;
}

.cb-icon-numeric.cb-green1-color {
  border-color: #3a913f;
}
.cb-icon-numeric.cb-green1-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #3a913f;
  background-color: #3a913f;
}

.cb-icon.cb-green2-color.cb-icon-circular::after {
  background-color: #6cc24a;
}

.cb-icon-numeric.cb-green2-color {
  border-color: #6cc24a;
}
.cb-icon-numeric.cb-green2-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #6cc24a;
  background-color: #6cc24a;
}

.cb-icon.cb-green3-color.cb-icon-circular::after {
  background-color: #1d7846;
}

.cb-icon-numeric.cb-green3-color {
  border-color: #1d7846;
}
.cb-icon-numeric.cb-green3-color.cb-icon-circular {
  color: #ffffff !important;
  border-color: #1d7846;
  background-color: #1d7846;
}

@media print {
  .cb-icon-numeric {
    border-color: #000 !important;
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-white] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-black1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-black2] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-blue1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-blue2] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-blue3] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-blue4] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-blue5] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-gray1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-gray2] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-gray3] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-gray4] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-gray5] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-yellow1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-orange1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-red1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-purple1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-purple2] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-green1] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-green2] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular[class*=cb-green3] {
    color: #000 !important;
  }
  .cb-icon-numeric.cb-icon-circular::before {
    color: #000 !important;
  }
}
.cb-print .cb-icon-numeric {
  border-color: #000 !important;
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-white] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-black1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-black2] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-blue1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-blue2] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-blue3] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-blue4] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-blue5] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-gray1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-gray2] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-gray3] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-gray4] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-gray5] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-yellow1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-orange1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-red1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-purple1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-purple2] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-green1] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-green2] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular[class*=cb-green3] {
  color: #000 !important;
}
.cb-print .cb-icon-numeric.cb-icon-circular::before {
  color: #000 !important;
}

.cb-play-video {
  font-size: 4rem;
}