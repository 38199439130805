.cb-css-transition-enter {
  max-height: 0;
  opacity: 0;
}

.cb-css-transition-enter-active {
  opacity: 1;
  transition: all 500ms;
}

.cb-css-transition-exit {
  opacity: 1;
}

.cb-css-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.cb-css-transition-display-enter {
  opacity: 0;
}

.cb-css-transition-display-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.cb-css-transition-display-exit {
  opacity: 1;
}

.cb-css-transition-display-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.cb-line-4 {
  height: 4px;
}

.cb-line4 {
  height: 4px;
}

.cb-line-8 {
  height: 8px;
}

.cb-line8 {
  height: 8px;
}

.cb-line-16 {
  height: 16px;
}

.cb-line16 {
  height: 16px;
}

.cb-line-24 {
  height: 24px;
}

.cb-line24 {
  height: 24px;
}

.cb-line-32 {
  height: 32px;
}

.cb-line32 {
  height: 32px;
}

.cb-line-48 {
  height: 48px;
}

.cb-line48 {
  height: 48px;
}

.cb-line-72 {
  height: 72px;
}

.cb-line72 {
  height: 72px;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-line-xs-4 {
    height: 4px;
  }
  .cb-line-xs-8 {
    height: 8px;
  }
  .cb-line-xs-16 {
    height: 16px;
  }
  .cb-line-xs-24 {
    height: 24px;
  }
  .cb-line-xs-32 {
    height: 32px;
  }
  .cb-line-xs-48 {
    height: 48px;
  }
  .cb-line-xs-72 {
    height: 72px;
  }
}
@media (min-width: 1px) {
  .cb-line-xs-up-4 {
    height: 4px;
  }
  .cb-line-xs-up-8 {
    height: 8px;
  }
  .cb-line-xs-up-16 {
    height: 16px;
  }
  .cb-line-xs-up-24 {
    height: 24px;
  }
  .cb-line-xs-up-32 {
    height: 32px;
  }
  .cb-line-xs-up-48 {
    height: 48px;
  }
  .cb-line-xs-up-72 {
    height: 72px;
  }
}
@media (max-width: 767.98px) {
  .cb-line-xs-down-4 {
    height: 4px;
  }
  .cb-line-xs-down-8 {
    height: 8px;
  }
  .cb-line-xs-down-16 {
    height: 16px;
  }
  .cb-line-xs-down-24 {
    height: 24px;
  }
  .cb-line-xs-down-32 {
    height: 32px;
  }
  .cb-line-xs-down-48 {
    height: 48px;
  }
  .cb-line-xs-down-72 {
    height: 72px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-line-sm-4 {
    height: 4px;
  }
  .cb-line-sm-8 {
    height: 8px;
  }
  .cb-line-sm-16 {
    height: 16px;
  }
  .cb-line-sm-24 {
    height: 24px;
  }
  .cb-line-sm-32 {
    height: 32px;
  }
  .cb-line-sm-48 {
    height: 48px;
  }
  .cb-line-sm-72 {
    height: 72px;
  }
}
@media (min-width: 768px) {
  .cb-line-sm-up-4 {
    height: 4px;
  }
  .cb-line-sm-up-8 {
    height: 8px;
  }
  .cb-line-sm-up-16 {
    height: 16px;
  }
  .cb-line-sm-up-24 {
    height: 24px;
  }
  .cb-line-sm-up-32 {
    height: 32px;
  }
  .cb-line-sm-up-48 {
    height: 48px;
  }
  .cb-line-sm-up-72 {
    height: 72px;
  }
}
@media (max-width: 1023.98px) {
  .cb-line-sm-down-4 {
    height: 4px;
  }
  .cb-line-sm-down-8 {
    height: 8px;
  }
  .cb-line-sm-down-16 {
    height: 16px;
  }
  .cb-line-sm-down-24 {
    height: 24px;
  }
  .cb-line-sm-down-32 {
    height: 32px;
  }
  .cb-line-sm-down-48 {
    height: 48px;
  }
  .cb-line-sm-down-72 {
    height: 72px;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-line-md-4 {
    height: 4px;
  }
  .cb-line-md-8 {
    height: 8px;
  }
  .cb-line-md-16 {
    height: 16px;
  }
  .cb-line-md-24 {
    height: 24px;
  }
  .cb-line-md-32 {
    height: 32px;
  }
  .cb-line-md-48 {
    height: 48px;
  }
  .cb-line-md-72 {
    height: 72px;
  }
}
@media (min-width: 1024px) {
  .cb-line-md-up-4 {
    height: 4px;
  }
  .cb-line-md-up-8 {
    height: 8px;
  }
  .cb-line-md-up-16 {
    height: 16px;
  }
  .cb-line-md-up-24 {
    height: 24px;
  }
  .cb-line-md-up-32 {
    height: 32px;
  }
  .cb-line-md-up-48 {
    height: 48px;
  }
  .cb-line-md-up-72 {
    height: 72px;
  }
}
@media (max-width: 1247.98px) {
  .cb-line-md-down-4 {
    height: 4px;
  }
  .cb-line-md-down-8 {
    height: 8px;
  }
  .cb-line-md-down-16 {
    height: 16px;
  }
  .cb-line-md-down-24 {
    height: 24px;
  }
  .cb-line-md-down-32 {
    height: 32px;
  }
  .cb-line-md-down-48 {
    height: 48px;
  }
  .cb-line-md-down-72 {
    height: 72px;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-line-lg-4 {
    height: 4px;
  }
  .cb-line-lg-8 {
    height: 8px;
  }
  .cb-line-lg-16 {
    height: 16px;
  }
  .cb-line-lg-24 {
    height: 24px;
  }
  .cb-line-lg-32 {
    height: 32px;
  }
  .cb-line-lg-48 {
    height: 48px;
  }
  .cb-line-lg-72 {
    height: 72px;
  }
}
@media (min-width: 1248px) {
  .cb-line-lg-up-4 {
    height: 4px;
  }
  .cb-line-lg-up-8 {
    height: 8px;
  }
  .cb-line-lg-up-16 {
    height: 16px;
  }
  .cb-line-lg-up-24 {
    height: 24px;
  }
  .cb-line-lg-up-32 {
    height: 32px;
  }
  .cb-line-lg-up-48 {
    height: 48px;
  }
  .cb-line-lg-up-72 {
    height: 72px;
  }
}
@media (max-width: 1343.98px) {
  .cb-line-lg-down-4 {
    height: 4px;
  }
  .cb-line-lg-down-8 {
    height: 8px;
  }
  .cb-line-lg-down-16 {
    height: 16px;
  }
  .cb-line-lg-down-24 {
    height: 24px;
  }
  .cb-line-lg-down-32 {
    height: 32px;
  }
  .cb-line-lg-down-48 {
    height: 48px;
  }
  .cb-line-lg-down-72 {
    height: 72px;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-line-xl-4 {
    height: 4px;
  }
  .cb-line-xl-8 {
    height: 8px;
  }
  .cb-line-xl-16 {
    height: 16px;
  }
  .cb-line-xl-24 {
    height: 24px;
  }
  .cb-line-xl-32 {
    height: 32px;
  }
  .cb-line-xl-48 {
    height: 48px;
  }
  .cb-line-xl-72 {
    height: 72px;
  }
}
@media (min-width: 1344px) {
  .cb-line-xl-up-4 {
    height: 4px;
  }
  .cb-line-xl-up-8 {
    height: 8px;
  }
  .cb-line-xl-up-16 {
    height: 16px;
  }
  .cb-line-xl-up-24 {
    height: 24px;
  }
  .cb-line-xl-up-32 {
    height: 32px;
  }
  .cb-line-xl-up-48 {
    height: 48px;
  }
  .cb-line-xl-up-72 {
    height: 72px;
  }
}
@media (max-width: 1439.98px) {
  .cb-line-xl-down-4 {
    height: 4px;
  }
  .cb-line-xl-down-8 {
    height: 8px;
  }
  .cb-line-xl-down-16 {
    height: 16px;
  }
  .cb-line-xl-down-24 {
    height: 24px;
  }
  .cb-line-xl-down-32 {
    height: 32px;
  }
  .cb-line-xl-down-48 {
    height: 48px;
  }
  .cb-line-xl-down-72 {
    height: 72px;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-line-2xl-4 {
    height: 4px;
  }
  .cb-line-2xl-8 {
    height: 8px;
  }
  .cb-line-2xl-16 {
    height: 16px;
  }
  .cb-line-2xl-24 {
    height: 24px;
  }
  .cb-line-2xl-32 {
    height: 32px;
  }
  .cb-line-2xl-48 {
    height: 48px;
  }
  .cb-line-2xl-72 {
    height: 72px;
  }
}
@media (min-width: 1440px) {
  .cb-line-2xl-up-4 {
    height: 4px;
  }
  .cb-line-2xl-up-8 {
    height: 8px;
  }
  .cb-line-2xl-up-16 {
    height: 16px;
  }
  .cb-line-2xl-up-24 {
    height: 24px;
  }
  .cb-line-2xl-up-32 {
    height: 32px;
  }
  .cb-line-2xl-up-48 {
    height: 48px;
  }
  .cb-line-2xl-up-72 {
    height: 72px;
  }
}
@media (max-width: 1727.98px) {
  .cb-line-2xl-down-4 {
    height: 4px;
  }
  .cb-line-2xl-down-8 {
    height: 8px;
  }
  .cb-line-2xl-down-16 {
    height: 16px;
  }
  .cb-line-2xl-down-24 {
    height: 24px;
  }
  .cb-line-2xl-down-32 {
    height: 32px;
  }
  .cb-line-2xl-down-48 {
    height: 48px;
  }
  .cb-line-2xl-down-72 {
    height: 72px;
  }
}
@media (min-width: 1728px) {
  .cb-line-3xl-4 {
    height: 4px;
  }
  .cb-line-3xl-8 {
    height: 8px;
  }
  .cb-line-3xl-16 {
    height: 16px;
  }
  .cb-line-3xl-24 {
    height: 24px;
  }
  .cb-line-3xl-32 {
    height: 32px;
  }
  .cb-line-3xl-48 {
    height: 48px;
  }
  .cb-line-3xl-72 {
    height: 72px;
  }
}
@media (min-width: 1728px) {
  .cb-line-3xl-up-4 {
    height: 4px;
  }
  .cb-line-3xl-up-8 {
    height: 8px;
  }
  .cb-line-3xl-up-16 {
    height: 16px;
  }
  .cb-line-3xl-up-24 {
    height: 24px;
  }
  .cb-line-3xl-up-32 {
    height: 32px;
  }
  .cb-line-3xl-up-48 {
    height: 48px;
  }
  .cb-line-3xl-up-72 {
    height: 72px;
  }
}
.cb-line-3xl-down-4 {
  height: 4px;
}

.cb-line-3xl-down-8 {
  height: 8px;
}

.cb-line-3xl-down-16 {
  height: 16px;
}

.cb-line-3xl-down-24 {
  height: 24px;
}

.cb-line-3xl-down-32 {
  height: 32px;
}

.cb-line-3xl-down-48 {
  height: 48px;
}

.cb-line-3xl-down-72 {
  height: 72px;
}

.cb-loader-shimmer.cb-loader-effect {
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  animation: shimmer 4s infinite linear;
  background-size: 1000px 100%;
}
@media (prefers-reduced-motion: reduce) {
  .cb-loader-shimmer.cb-loader-effect {
    animation: none;
  }
}
.cb-loader-shimmer:not(.cb-loader-light) div[class*=cb-line] {
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  animation: shimmer 4s infinite linear;
  background-size: 1000px 100%;
  border-radius: 10px;
}
@media (prefers-reduced-motion: reduce) {
  .cb-loader-shimmer:not(.cb-loader-light) div[class*=cb-line] {
    animation: none;
  }
}
.cb-loader-shimmer.cb-loader-light div[class*=cb-line] {
  background: linear-gradient(to right, #eff1f3 4%, #fffefe 25%, #eff1f3 36%);
  animation: shimmer 4s infinite linear;
  background-size: 1000px 100%;
  border-radius: 10px;
}
@media (prefers-reduced-motion: reduce) {
  .cb-loader-shimmer.cb-loader-light div[class*=cb-line] {
    animation: none;
  }
}
.cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(1), .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(3), .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(4), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(1), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(3), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(4) {
  width: 300px;
}
.cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(2), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(2) {
  width: 400px;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(1), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(1) {
    width: 200px;
  }
  .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(2), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(2) {
    width: 80%;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(1), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(1) {
    width: 100px;
  }
  .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(2), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(2) {
    width: 200px;
  }
  .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(3), .cb-loader-shimmer.cb-loader-band [class*=cb-line]:nth-of-type(4), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(3), .cb-loader-shimmer.cb-loader-hero-band [class*=cb-line]:nth-of-type(4) {
    width: 100%;
  }
}
.cb-loader-shimmer.cb-loader-section [class*=cb-line]:first-of-type {
  width: 200px;
}
.cb-loader-shimmer.cb-loader-hero-band .justify-content-center {
  align-items: center;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}