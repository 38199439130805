.cb-glyph-logo {
  position: relative;
  display: inline-block;
  background-image: none !important;
  font-family: CB Glyphs Logo;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cb-glyph-logo::before {
  position: absolute;
  top: 0;
  left: 0;
}
.cb-glyph-logo.cb-logo-acorn::before {
  content: "\e901";
}
.cb-glyph-logo.cb-logo::before {
  content: "\e902";
}
.cb-glyph-logo.cb-programs-access::before {
  content: "\e903";
}
.cb-glyph-logo.cb-programs-accuplacer::before {
  content: "\e904";
}
.cb-glyph-logo.cb-cb-programs-ap::before {
  content: "\e905";
}
.cb-glyph-logo.cb-programs-ap::before {
  content: "\e905";
}
.cb-glyph-logo.cb-programs-clep::before {
  content: "\e906";
}
.cb-glyph-logo.cb-programs-css-profile::before {
  content: "\e907";
}
.cb-glyph-logo.cb-programs-powerfaids::before {
  content: "\e908";
}
.cb-glyph-logo.cb-programs-pre-ap::before {
  content: "\e909";
}
.cb-glyph-logo.cb-programs-psat-8-9::before {
  content: "\e90a";
}
.cb-glyph-logo.cb-programs-psat-10::before {
  content: "\e90b";
}
.cb-glyph-logo.cb-programs-psat-nmsqt::before {
  content: "\e90c";
}
.cb-glyph-logo.cb-programs-sat::before {
  content: "\e90d";
}
.cb-glyph-logo.cb-programs-springboard::before {
  content: "\e90e";
}
.cb-glyph-logo.cb-search::before {
  content: "\e90f";
}
.cb-glyph-logo.cb-programs-college-planning::before {
  content: "\e900";
}
.cb-glyph-logo.cb-programs-big-future::before {
  content: "\e911";
}
.cb-glyph-logo.cb-higher-ed-search::before {
  content: "\e910";
}