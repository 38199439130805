.cb-event-timeline ol,
.cb-event-timeline .row {
  padding: 0;
  display: flex;
}
.cb-event-timeline ol .cb-card,
.cb-event-timeline .row .cb-card {
  display: flex;
  flex-direction: column;
}
.cb-event-timeline ol .cb-card .cb-event-date,
.cb-event-timeline .row .cb-card .cb-event-date {
  display: flex;
  flex-direction: column;
}
.cb-event-timeline ol .cb-card .cb-event-date .cb-event-year,
.cb-event-timeline .row .cb-card .cb-event-date .cb-event-year {
  font-size: 1.4375rem;
  line-height: 24px;
  font-weight: 700;
}
.cb-event-timeline ol .cb-card .cb-event-date .cb-event-year.cb-event-year-repeat,
.cb-event-timeline .row .cb-card .cb-event-date .cb-event-year.cb-event-year-repeat {
  color: transparent;
  padding: 0 !important;
  height: 1px;
}
.cb-event-timeline ol .cb-card .cb-event-date .cb-date-time-tags,
.cb-event-timeline .row .cb-card .cb-event-date .cb-date-time-tags {
  margin-bottom: 12px;
}
.cb-event-timeline ol .cb-card .cb-event-info,
.cb-event-timeline .row .cb-card .cb-event-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.cb-event-timeline ol .cb-card .cb-event-info .cb-event-title,
.cb-event-timeline .row .cb-card .cb-event-info .cb-event-title {
  position: relative;
  font-size: 1.3125rem;
  line-height: 1.1428571429em;
  font-weight: 500;
  margin-top: 12px;
}
.cb-event-timeline ol .cb-card .cb-event-info .cb-event-desc,
.cb-event-timeline .row .cb-card .cb-event-info .cb-event-desc {
  margin-top: 8px;
}
.cb-event-timeline ol .cb-card .cb-event-info .cb-meta-tags,
.cb-event-timeline .row .cb-card .cb-event-info .cb-meta-tags {
  padding-top: 16px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .cb-event-timeline.cb-event-cards-horizontal {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol,
  .cb-event-timeline.cb-event-cards-horizontal .row {
    flex-wrap: nowrap;
    padding: 8px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card {
    width: 320px;
    min-width: 320px;
    margin-top: 72px;
    position: relative;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card::before {
    position: absolute;
    display: block;
    content: "";
    height: 2px;
    width: calc(100% + 24px);
    background-color: #d9d9d9;
    top: -24px;
    left: 24px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card::after,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: -31px;
    left: 24px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:last-of-type::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:last-of-type::before {
    width: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header {
    flex-flow: column-reverse;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-card-label,
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-btn-tag,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-card-label,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-btn-tag {
    margin-bottom: 16px;
    max-width: fit-content;
    display: inline-flex;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-date-time-tags,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-date-time-tags {
    position: relative;
    margin-bottom: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-date-time-tags p,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-date-time-tags p {
    text-transform: uppercase;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-content,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-content .cb-card-title,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-content .cb-card-title {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-content .cb-card-title + .cb-card-action,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-content .cb-card-title + .cb-card-action {
    margin-top: 8px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card.cb-event-year-stamp .cb-event-year,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card.cb-event-year-stamp .cb-event-year {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 32px;
    position: absolute;
    top: -72px;
    left: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol.cb-gutterh-24,
  .cb-event-timeline.cb-event-cards-horizontal .row.cb-gutterh-24 {
    margin-left: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol.cb-gutterh-24 .cb-card:first-of-type,
  .cb-event-timeline.cb-event-cards-horizontal .row.cb-gutterh-24 .cb-card:first-of-type {
    margin-left: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal .cb-has-label .cb-card-content .cb-card-title {
    -webkit-line-clamp: 2 !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] .cb-card::before {
    width: calc(100% + 24px);
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-]:last-of-type .cb-card::before {
    width: 0 !important;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-horizontal {
    margin-left: 8px;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) and (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-horizontal {
    margin-left: 0;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-horizontal ol,
  .cb-event-timeline.cb-event-cards-horizontal .row {
    flex-direction: column;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    margin-left: 24px;
    position: relative;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card + .cb-card,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card + .cb-card {
    margin-top: 24px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card::before {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    height: calc(100% + 24px);
    background-color: #d9d9d9;
    top: -24px;
    left: -24px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:first-of-type::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:first-of-type::before {
    height: calc(100% - 6px);
    top: 32px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card.cb-event-year-stamp,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card.cb-event-year-stamp {
    margin-top: 48px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card.cb-event-year-stamp .cb-event-year,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card.cb-event-year-stamp .cb-event-year {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 32px;
    position: absolute;
    top: -48px;
    left: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card + .cb-card.cb-event-year-stamp,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card + .cb-card.cb-event-year-stamp {
    margin-top: 92px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card + .cb-card.cb-event-year-stamp::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card + .cb-card.cb-event-year-stamp::before {
    height: calc(100% + 92px);
    top: -92px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:last-of-type,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:last-of-type {
    padding-bottom: 24px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:last-of-type::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:last-of-type::before {
    height: 64px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:last-of-type.cb-event-year-stamp::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:last-of-type.cb-event-year-stamp::before {
    height: 120px;
    top: -92px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-date-time-tags,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-date-time-tags {
    position: relative;
    margin-bottom: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-date-time-tags::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-date-time-tags::before {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: 4px;
    left: -55px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-date-time-tags p,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-date-time-tags p {
    text-transform: uppercase;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) and (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card {
    margin-top: 48px !important;
    margin-left: 0;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card::before {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    top: -48px !important;
    height: 48px !important;
    background-color: #d9d9d9;
    left: calc(50% - 1px);
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card::after,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: -32px;
    left: calc(50% - 8px);
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:first-of-type:not(.cb-event-year-stamp),
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:first-of-type:not(.cb-event-year-stamp) {
    margin-top: 0 !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:first-of-type::before, .cb-event-timeline.cb-event-cards-horizontal ol .cb-card:first-of-type::after,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:first-of-type::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card:first-of-type::after {
    display: none;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card + .cb-card.cb-event-year-stamp,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card + .cb-card.cb-event-year-stamp {
    margin-top: 88px !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card + .cb-card.cb-event-year-stamp::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card + .cb-card.cb-event-year-stamp::before {
    height: 88px !important;
    top: -88px !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card + .cb-card.cb-event-year-stamp::after,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card + .cb-card.cb-event-year-stamp::after {
    top: -52px;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-date-time-tags::before,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-date-time-tags::before {
    display: none;
  }
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-card-label,
  .cb-event-timeline.cb-event-cards-horizontal ol .cb-card .cb-card-header .cb-btn-tag,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-card-label,
  .cb-event-timeline.cb-event-cards-horizontal .row .cb-card .cb-card-header .cb-btn-tag {
    width: -moz-fit-content;
    max-width: -moz-fit-content;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) and (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-horizontal ol.cb-gutterh-24,
  .cb-event-timeline.cb-event-cards-horizontal .row.cb-gutterh-24 {
    margin-left: 0;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) and (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] {
    margin-top: 48px !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] .cb-card::before {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    top: -48px !important;
    height: 48px !important;
    background-color: #d9d9d9;
    left: calc(50% - 1px);
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] .cb-card::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: -32px;
    left: calc(50% - 8px);
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-]:first-of-type .cb-card:not(.cb-event-year-stamp) {
    margin-top: 0 !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-]:first-of-type .cb-card::before, .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-]:first-of-type .cb-card::after {
    display: none;
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] + div[class*=col-] .cb-card.cb-event-year-stamp {
    margin-top: 40px !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] + div[class*=col-] .cb-card.cb-event-year-stamp::before {
    height: 88px !important;
    top: -88px !important;
  }
  .cb-event-timeline.cb-event-cards-horizontal .row div[class*=col-] + div[class*=col-] .cb-card.cb-event-year-stamp::after {
    top: -52px;
  }
}
.cb-event-timeline.cb-event-cards-horizontal div[class*=col-] .cb-card {
  height: calc(100% - 72px);
  width: auto;
  min-width: auto;
}
.cb-event-timeline.cb-event-cards-vertical {
  margin-left: 8px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-vertical {
    margin-left: 0;
  }
}
.cb-event-timeline.cb-event-cards-vertical ol,
.cb-event-timeline.cb-event-cards-vertical .row {
  flex-direction: column;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  margin-left: 24px;
  position: relative;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card + .cb-card,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card + .cb-card {
  margin-top: 24px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card::before,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card::before {
  position: absolute;
  display: block;
  content: "";
  width: 2px;
  height: calc(100% + 24px);
  background-color: #d9d9d9;
  top: -24px;
  left: -24px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card:first-of-type::before,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card:first-of-type::before {
  height: calc(100% - 6px);
  top: 32px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card.cb-event-year-stamp,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card.cb-event-year-stamp {
  margin-top: 48px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card.cb-event-year-stamp .cb-event-year,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card.cb-event-year-stamp .cb-event-year {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: -48px;
  left: 0;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card + .cb-card.cb-event-year-stamp,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card + .cb-card.cb-event-year-stamp {
  margin-top: 92px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card + .cb-card.cb-event-year-stamp::before,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card + .cb-card.cb-event-year-stamp::before {
  height: calc(100% + 92px);
  top: -92px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card:last-of-type,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card:last-of-type {
  padding-bottom: 24px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card:last-of-type::before,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card:last-of-type::before {
  height: 64px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card:last-of-type.cb-event-year-stamp::before,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card:last-of-type.cb-event-year-stamp::before {
  height: 120px;
  top: -92px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card .cb-card-header .cb-date-time-tags,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card .cb-card-header .cb-date-time-tags {
  position: relative;
  margin-bottom: 0;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card .cb-card-header .cb-date-time-tags::before,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card .cb-card-header .cb-date-time-tags::before {
  position: absolute;
  display: block;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #d9d9d9;
  top: 4px;
  left: -55px;
}
.cb-event-timeline.cb-event-cards-vertical ol .cb-card .cb-card-header .cb-date-time-tags p,
.cb-event-timeline.cb-event-cards-vertical .row .cb-card .cb-card-header .cb-date-time-tags p {
  text-transform: uppercase;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card {
    margin-top: 48px !important;
    margin-left: 0;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card::before,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card::before {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    top: -48px !important;
    height: 48px !important;
    background-color: #d9d9d9;
    left: calc(50% - 1px);
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card::after,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: -32px;
    left: calc(50% - 8px);
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card:first-of-type:not(.cb-event-year-stamp),
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card:first-of-type:not(.cb-event-year-stamp) {
    margin-top: 0 !important;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card:first-of-type::before, .cb-event-timeline.cb-event-cards-vertical ol .cb-card:first-of-type::after,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card:first-of-type::before,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card:first-of-type::after {
    display: none;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card + .cb-card.cb-event-year-stamp,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card + .cb-card.cb-event-year-stamp {
    margin-top: 88px !important;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card + .cb-card.cb-event-year-stamp::before,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card + .cb-card.cb-event-year-stamp::before {
    height: 88px !important;
    top: -88px !important;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card + .cb-card.cb-event-year-stamp::after,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card + .cb-card.cb-event-year-stamp::after {
    top: -52px;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card .cb-card-header .cb-date-time-tags::before,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card .cb-card-header .cb-date-time-tags::before {
    display: none;
  }
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card .cb-card-header .cb-card-label,
  .cb-event-timeline.cb-event-cards-vertical ol .cb-card .cb-card-header .cb-btn-tag,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card .cb-card-header .cb-card-label,
  .cb-event-timeline.cb-event-cards-vertical .row .cb-card .cb-card-header .cb-btn-tag {
    width: -moz-fit-content;
    max-width: -moz-fit-content;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-vertical ol.cb-gutterh-24,
  .cb-event-timeline.cb-event-cards-vertical .row.cb-gutterh-24 {
    margin-left: 0;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-] {
    margin-top: 48px !important;
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-] .cb-card::before {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    top: -48px !important;
    height: 48px !important;
    background-color: #d9d9d9;
    left: calc(50% - 1px);
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-] .cb-card::after {
    position: absolute;
    display: block;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: -32px;
    left: calc(50% - 8px);
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-]:first-of-type .cb-card:not(.cb-event-year-stamp) {
    margin-top: 0 !important;
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-]:first-of-type .cb-card::before, .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-]:first-of-type .cb-card::after {
    display: none;
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-] + div[class*=col-] .cb-card.cb-event-year-stamp {
    margin-top: 40px !important;
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-] + div[class*=col-] .cb-card.cb-event-year-stamp::before {
    height: 88px !important;
    top: -88px !important;
  }
  .cb-event-timeline.cb-event-cards-vertical .row div[class*=col-] + div[class*=col-] .cb-card.cb-event-year-stamp::after {
    top: -52px;
  }
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-horizontal-carousel .cb-event-cards-horizontal ol,
  .cb-horizontal-carousel .cb-event-cards-horizontal .row {
    padding: 0 8px !important;
  }
}