.cb-scrollbar-container {
  margin-bottom: 24px;
}
.cb-scrollbar-container.active {
  margin-bottom: 96px;
  position: relative;
  overflow: hidden;
  border: 1px solid #d9d9d9;
}
.cb-scrollbar-container.active .cb-table-responsive {
  position: relative;
  overflow: hidden;
}
.cb-scrollbar-container.active .cb-table {
  position: absolute;
  border-bottom: 0px !important;
}
.cb-scrollbar-container.active .cb-table > thead > tr > th:first-child,
.cb-scrollbar-container.active .cb-table > thead > tr > td:first-child,
.cb-scrollbar-container.active .cb-table > tbody > tr > th:first-child,
.cb-scrollbar-container.active .cb-table > tbody > tr > td:first-child,
.cb-scrollbar-container.active .cb-table > tfoot > tr > th:first-child,
.cb-scrollbar-container.active .cb-table > tfoot > tr > td:first-child {
  border-left: 0;
}
.cb-scrollbar-container.active .cb-table > thead > tr > th:last-child,
.cb-scrollbar-container.active .cb-table > thead > tr > td:last-child,
.cb-scrollbar-container.active .cb-table > tbody > tr > th:last-child,
.cb-scrollbar-container.active .cb-table > tbody > tr > td:last-child,
.cb-scrollbar-container.active .cb-table > tfoot > tr > th:last-child,
.cb-scrollbar-container.active .cb-table > tfoot > tr > td:last-child {
  border-right: 0;
}
.cb-scrollbar-container.active .cb-table > caption {
  border-left: 0;
  border-right: 0;
}
.cb-scrollbar-container.active .cb-scrollbar {
  position: relative;
  width: 100%;
  height: 14px;
  padding: 2px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  display: block;
}
.cb-scrollbar-container.active .cb-scrollbar .cb-scrollbar-thumb {
  position: absolute;
  height: 10px;
  min-width: 30px;
  left: 0;
  background-color: #888888;
  opacity: 0.4;
  border-radius: 8px;
}
.cb-scrollbar-container.active .cb-scrollbar:hover .cb-scrollbar-thumb {
  opacity: 0.7;
}
.cb-scrollbar-container .cb-table-responsive {
  border: 0;
  margin-bottom: 0;
  overflow-y: hidden;
}
.cb-scrollbar-container .cb-table-responsive .table {
  margin-bottom: 0;
}

.not-selectable {
  user-select: none;
}