.cb-local-navigation.cb-sticky-local-navigation {
  width: 100%;
  z-index: 901;
  position: sticky;
  top: 0;
  left: 0;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-local-navigation > .cb-desktop-navigation > .row {
    margin-right: -24px;
  }
  .cb-local-navigation > .cb-desktop-navigation > .row .col-xs.cb-nav-container {
    padding-right: 0;
  }
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container {
  display: flex;
  justify-content: space-between;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name {
  display: inline-flex;
  justify-content: flex-start;
  min-width: fit-content;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name {
    height: 48px;
    align-items: center;
  }
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a {
  font-size: 1.4375rem;
  line-height: 1.0434782609em;
  font-weight: 500;
  text-decoration: none;
  padding: 36px 24px 12px 0;
  white-space: nowrap;
  color: #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-focus {
  color: #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:active, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-active {
  color: #505050;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:visited, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-visited {
  color: #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled.cb-hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled:focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled.cb-focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled.cb-active, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled:visited, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a:visited {
  text-decoration: underline;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a {
    font-size: 1.1875rem;
    line-height: 1.2631578947em;
    padding: 0;
  }
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items {
  display: inline-flex;
  flex-basis: 100%;
  justify-content: flex-end;
  height: 72px;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal {
  width: 100%;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul {
  width: 100%;
  justify-content: flex-end;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li {
  display: flex;
  justify-content: flex-end;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li.cb-promotional.cb-last-of-type {
  margin-right: auto;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-menu-link {
  white-space: nowrap;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown > a:focus {
  outline: auto;
  outline-color: #7ab6fd;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-toggle {
  height: 100%;
  white-space: nowrap;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-toggle:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-toggle.cb-hover {
  background-color: #f0f0f0;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-menu ul {
  flex-direction: column;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-menu ul > li > a:hover {
  box-shadow: inset 2px 0px 0px 0px #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-menu ul > li > a.cb-selected {
  box-shadow: inset 2px 0px 0px 0px #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-nav-items .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-menu ul .cb-nested-menu {
  display: none;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #d9d9d9;
  padding: 16px;
  min-width: fit-content;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a {
  color: #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-focus {
  color: #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:active, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-active {
  color: #505050;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:visited, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-visited {
  color: #1e1e1e;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled.cb-hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled:focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled.cb-focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled.cb-active, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled:visited, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:hover, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:focus, .cb-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-mobile-trigger a:visited {
  text-decoration: none;
}
.cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel {
  display: none;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel {
    display: block;
    z-index: 901;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: calc(-100% - 40px);
    transition: all 0.25s;
    transition-delay: 0s;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    padding: 16px 0 24px 0;
    background-color: #ffffff;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation {
    display: flex;
    padding: 0 16px 16px 24px;
    border-bottom: 1px solid #d9d9d9;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link {
    color: #1e1e1e;
    line-height: 16px;
    text-decoration: none;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:hover, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-hover, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:focus, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-focus,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:hover,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-hover,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:focus,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-focus {
    color: #1e1e1e;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:active, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-active,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:active,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-active {
    color: #505050;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:visited, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-visited,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:visited,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-visited {
    color: #1e1e1e;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled {
    color: #b2b2b2 !important;
    text-decoration: none !important;
    cursor: not-allowed;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled:hover, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-hover, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled:focus, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-focus, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-active, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled:visited, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-visited,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled:hover,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-hover,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled:focus,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-focus,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-active,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled:visited,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-visited {
    color: #ffffff;
    color: #b2b2b2 !important;
    text-decoration: none !important;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:hover, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:focus, .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:visited,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:hover,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:focus,
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:visited {
    text-decoration: none;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link {
    font-size: 0.875rem;
    line-height: 16px;
    font-weight: 500;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link {
    margin-left: auto;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-menu-list-vertical {
    overflow-y: auto;
    height: calc(100vh - 48px);
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-menu-link {
    padding-right: 16px;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-nested-navigation .cb-menu-link {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }
  .cb-local-navigation > .cb-mobile-navigation .cb-mobile-panel.cb-panel-show {
    left: 0;
    transition: all 0.25s ease-in-out;
  }
}
@media (max-width: 1023.98px) {
  .cb-local-navigation.cb-tablet-local-navigation > .cb-desktop-navigation {
    max-width: 100%;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-desktop-navigation > .row {
    margin-right: -24px;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-desktop-navigation > .row .col-xs.cb-nav-container {
    padding-right: 0;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name {
    height: 48px;
    align-items: center;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-desktop-navigation .cb-nav-container .cb-site-name a {
    font-size: 1.1875rem;
    line-height: 1.2631578947em;
    padding: 0;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel {
    display: block;
    z-index: 901;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: calc(-100% - 40px);
    transition: all 0.25s;
    transition-delay: 0s;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    padding: 16px 0 24px 0;
    background-color: #ffffff;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation {
    display: flex;
    padding: 0 16px 16px 24px;
    border-bottom: 1px solid #d9d9d9;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link {
    color: #1e1e1e;
    line-height: 16px;
    text-decoration: none;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:hover, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-hover, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:focus, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-focus,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:hover,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-hover,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:focus,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-focus {
    color: #1e1e1e;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:active, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-active,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:active,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-active {
    color: #505050;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:visited, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-visited,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:visited,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-visited {
    color: #1e1e1e;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled {
    color: #b2b2b2 !important;
    text-decoration: none !important;
    cursor: not-allowed;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled:hover, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-hover, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled:focus, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-focus, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-active, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled:visited, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link.cb-disabled.cb-visited,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled:hover,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-hover,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled:focus,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-focus,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-active,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled:visited,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link.cb-disabled.cb-visited {
    color: #ffffff;
    color: #b2b2b2 !important;
    text-decoration: none !important;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:hover, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:focus, .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link:visited,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:hover,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:focus,
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link:visited {
    text-decoration: none;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-back-link {
    font-size: 0.875rem;
    line-height: 16px;
    font-weight: 500;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-panel-navigation .cb-close-link {
    margin-left: auto;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-menu-list-vertical {
    overflow-y: auto;
    height: calc(100vh - 48px);
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-menu-link {
    padding-right: 16px;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel .cb-nested-navigation .cb-menu-link {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }
  .cb-local-navigation.cb-tablet-local-navigation > .cb-mobile-navigation .cb-mobile-panel.cb-panel-show {
    left: 0;
    transition: all 0.25s ease-in-out;
  }
}

.cb-sticky-local-navigation .cb-local-navigation > .cb-desktop-navigation {
  z-index: 901;
  position: fixed;
  top: 0;
  left: 0;
}

.cb-local-navigation-lock {
  overflow-y: hidden;
}

[data-cb-input-m=keyboard] .cb-local-navigation > .container-fluid .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-toggle:focus, [data-cb-input-m=keyboard] .cb-local-navigation > .container-fluid .cb-menu-list-horizontal > ul > li .cb-dropdown .cb-dropdown-toggle.cb-focus {
  text-decoration: underline !important;
}