.cb-notification {
  border: 1px solid #d9d9d9;
  height: auto;
  transition: height 0.35s ease;
}
.cb-notification .cb-notification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.cb-notification .cb-notification-container .cb-notification-header {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.cb-notification .cb-notification-container .cb-notification-header > .cb-glyph,
.cb-notification .cb-notification-container .cb-notification-header > .cb-icon {
  margin-right: 16px;
  flex-shrink: 0;
}
.cb-notification .cb-notification-container .cb-notification-header .cb-btn-close {
  margin-left: auto;
}
.cb-notification .cb-notification-container .cb-notification-title {
  font-size: 1.3125rem;
  line-height: 1.1428571429em;
  font-weight: 400;
}
.cb-notification .cb-notification-container .cb-notification-content {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  padding-left: 48px;
}
.cb-notification .cb-notification-container .cb-notification-content .cb-btn-row {
  margin-top: 24px;
}
.cb-notification .cb-notification-container .cb-notification-header + .cb-notification-content {
  margin-top: 12px;
}
@media (prefers-reduced-motion: reduce) {
  .cb-notification {
    transition: none;
  }
}
.cb-notification.cb-notification-hide {
  border: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
}