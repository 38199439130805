.cb-menu-item-radio {
  padding: 0;
  list-style: none;
}
.cb-menu-item-radio button {
  background: transparent;
  border: 0;
  position: relative;
  padding-left: 32px;
  text-align: left;
}
.cb-menu-item-radio button:before {
  content: "";
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  background-color: #ffffff;
  transition: all 0.2 ease-out;
  border: 1px solid #1e1e1e;
  border-radius: 50%;
  outline: none;
}
.cb-menu-item-radio button::after {
  position: absolute;
  display: block;
  box-sizing: border-box;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 50%;
  background-color: #324dc7;
  border: 0 solid #324dc7;
  opacity: 1;
  transition: all 0.3s ease-out;
}
.cb-menu-item-radio button[aria-checked=true]::before {
  border-color: #324dc7;
  border-width: 2px;
  background-color: #ffffff;
}
.cb-menu-item-radio button[aria-checked=true]::after {
  width: 0;
  height: 0;
  border: 7px solid #324dc7;
  top: 5px;
  left: 5px;
}
.cb-menu-item-radio button:hover::before {
  background-color: #f0f0f0;
  border: 1px solid #324dc7;
}
.cb-menu-item-radio button:disabled::before, .cb-menu-item-radio button.cb-disabled::before {
  cursor: not-allowed !important;
  border: 1px solid #d9d9d9 !important;
  background: #f0f0f0 !important;
}
.cb-menu-item-radio button:disabled::after, .cb-menu-item-radio button.cb-disabled::after {
  border-color: #d9d9d9;
}
.cb-menu-item-radio button:disabled[aria-checked=true]::after, .cb-menu-item-radio button.cb-disabled[aria-checked=true]::after {
  background: #d9d9d9;
  border-color: #d9d9d9;
}
.cb-menu-item-radio .cb-label-left {
  padding: 8px;
  background: transparent;
  margin-left: -8px;
  padding-right: 16px;
}
.cb-menu-item-radio .cb-label-left button {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}
.cb-menu-item-radio .cb-label-left button:before {
  left: auto;
  right: 0px;
  top: 0px;
}
.cb-menu-item-radio .cb-label-left button::after {
  left: auto;
  right: 12px;
}
.cb-menu-item-radio .cb-label-left button[aria-checked=true]::after {
  left: auto;
  right: 5px;
}
.cb-menu-item-radio .cb-label-left:hover {
  cursor: pointer;
  border-radius: 4px;
  background: #f0f0f0;
  transition: all 0.25s ease-in-out;
}

[data-cb-input-m=keyboard] .cb-menu-item-radio button:focus {
  outline: none;
}
[data-cb-input-m=keyboard] .cb-menu-item-radio button:focus::before {
  box-shadow: 0 0 0 2px #324dc7;
}