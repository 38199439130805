.cb-sticky.cb-sticky-active {
  z-index: 900;
  position: sticky;
  left: 0;
}
.cb-sticky.cb-sticky-active.cb-sticky-top {
  top: 0;
}
.cb-sticky.cb-sticky-active.cb-sticky-bottom {
  bottom: 0;
}