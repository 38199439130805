.cb-tooltip {
  position: absolute;
  background: #505050;
  color: #ffffff;
  min-width: 180px;
  max-width: 360px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1070;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  visibility: hidden;
}
.cb-tooltip .cb-tooltip-inner {
  font-size: 14px;
  padding: 12px;
}
.cb-tooltip.cb-tooltip-light {
  background-color: #ffffff;
  color: #1e1e1e;
}
.cb-tooltip[data-popper-placement^=top], .cb-tooltip[data-popper-placement^=bottom], .cb-tooltip[data-popper-placement^=right], .cb-tooltip[data-popper-placement^=left] {
  opacity: 1;
}