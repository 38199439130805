.cb-text-icon-left {
  font-size: 16px;
  line-height: 1.5em;
}
.cb-text-icon-left .cb-glyph,
.cb-text-icon-left .cb-icon {
  margin-right: 8px;
}

.cb-text-icon-right {
  font-size: 16px;
  line-height: 1.5em;
}
.cb-text-icon-right .cb-glyph,
.cb-text-icon-right .cb-icon {
  margin-left: 8px;
}

.cb-text-icon-left-sm,
.cb-text-icon-left-14 {
  font-size: 0.875rem !important;
  line-height: 1.1428571429em !important;
}
.cb-text-icon-left-sm .cb-glyph,
.cb-text-icon-left-sm .cb-icon,
.cb-text-icon-left-14 .cb-glyph,
.cb-text-icon-left-14 .cb-icon {
  margin-right: 8px;
}

.cb-text-icon-right-sm,
.cb-text-icon-right-14 {
  font-size: 0.875rem !important;
  line-height: 1.1428571429em !important;
}
.cb-text-icon-right-sm .cb-glyph,
.cb-text-icon-right-sm .cb-icon,
.cb-text-icon-right-14 .cb-glyph,
.cb-text-icon-right-14 .cb-icon {
  margin-left: 8px;
}

.cb-text-icon-left-xs,
.cb-text-icon-left-12 {
  font-size: 0.75rem !important;
  line-height: 1.3333333333em !important;
}
.cb-text-icon-left-xs .cb-glyph,
.cb-text-icon-left-xs .cb-icon,
.cb-text-icon-left-12 .cb-glyph,
.cb-text-icon-left-12 .cb-icon {
  margin-right: 8px;
}

.cb-text-icon-right-xs,
.cb-text-icon-right-12 {
  font-size: 0.75rem !important;
  line-height: 1.3333333333em !important;
}
.cb-text-icon-right-xs .cb-glyph,
.cb-text-icon-right-xs .cb-icon,
.cb-text-icon-right-12 .cb-glyph,
.cb-text-icon-right-12 .cb-icon {
  margin-left: 8px;
  font-size: 0.625rem !important;
  line-height: 1.6em !important;
}

a.cb-text-icon-left, a.cb-text-icon-right, a.cb-text-icon-left-md, a.cb-text-icon-right-md, a.cb-text-icon-left-sm, a.cb-text-icon-right-sm, a.cb-text-icon-left-xs, a.cb-text-icon-right-xs {
  text-decoration: none;
}
a.cb-text-icon-left:hover, a.cb-text-icon-left.cb-hover, a.cb-text-icon-left:active, a.cb-text-icon-left.cb-active, a.cb-text-icon-right:hover, a.cb-text-icon-right.cb-hover, a.cb-text-icon-right:active, a.cb-text-icon-right.cb-active, a.cb-text-icon-left-md:hover, a.cb-text-icon-left-md.cb-hover, a.cb-text-icon-left-md:active, a.cb-text-icon-left-md.cb-active, a.cb-text-icon-right-md:hover, a.cb-text-icon-right-md.cb-hover, a.cb-text-icon-right-md:active, a.cb-text-icon-right-md.cb-active, a.cb-text-icon-left-sm:hover, a.cb-text-icon-left-sm.cb-hover, a.cb-text-icon-left-sm:active, a.cb-text-icon-left-sm.cb-active, a.cb-text-icon-right-sm:hover, a.cb-text-icon-right-sm.cb-hover, a.cb-text-icon-right-sm:active, a.cb-text-icon-right-sm.cb-active, a.cb-text-icon-left-xs:hover, a.cb-text-icon-left-xs.cb-hover, a.cb-text-icon-left-xs:active, a.cb-text-icon-left-xs.cb-active, a.cb-text-icon-right-xs:hover, a.cb-text-icon-right-xs.cb-hover, a.cb-text-icon-right-xs:active, a.cb-text-icon-right-xs.cb-active {
  text-decoration: none !important;
}
a.cb-text-icon-left:hover span:not(.cb-glyph),
a.cb-text-icon-left:hover span:not(.cb-icon), a.cb-text-icon-left.cb-hover span:not(.cb-glyph),
a.cb-text-icon-left.cb-hover span:not(.cb-icon), a.cb-text-icon-left:active span:not(.cb-glyph),
a.cb-text-icon-left:active span:not(.cb-icon), a.cb-text-icon-left.cb-active span:not(.cb-glyph),
a.cb-text-icon-left.cb-active span:not(.cb-icon), a.cb-text-icon-right:hover span:not(.cb-glyph),
a.cb-text-icon-right:hover span:not(.cb-icon), a.cb-text-icon-right.cb-hover span:not(.cb-glyph),
a.cb-text-icon-right.cb-hover span:not(.cb-icon), a.cb-text-icon-right:active span:not(.cb-glyph),
a.cb-text-icon-right:active span:not(.cb-icon), a.cb-text-icon-right.cb-active span:not(.cb-glyph),
a.cb-text-icon-right.cb-active span:not(.cb-icon), a.cb-text-icon-left-md:hover span:not(.cb-glyph),
a.cb-text-icon-left-md:hover span:not(.cb-icon), a.cb-text-icon-left-md.cb-hover span:not(.cb-glyph),
a.cb-text-icon-left-md.cb-hover span:not(.cb-icon), a.cb-text-icon-left-md:active span:not(.cb-glyph),
a.cb-text-icon-left-md:active span:not(.cb-icon), a.cb-text-icon-left-md.cb-active span:not(.cb-glyph),
a.cb-text-icon-left-md.cb-active span:not(.cb-icon), a.cb-text-icon-right-md:hover span:not(.cb-glyph),
a.cb-text-icon-right-md:hover span:not(.cb-icon), a.cb-text-icon-right-md.cb-hover span:not(.cb-glyph),
a.cb-text-icon-right-md.cb-hover span:not(.cb-icon), a.cb-text-icon-right-md:active span:not(.cb-glyph),
a.cb-text-icon-right-md:active span:not(.cb-icon), a.cb-text-icon-right-md.cb-active span:not(.cb-glyph),
a.cb-text-icon-right-md.cb-active span:not(.cb-icon), a.cb-text-icon-left-sm:hover span:not(.cb-glyph),
a.cb-text-icon-left-sm:hover span:not(.cb-icon), a.cb-text-icon-left-sm.cb-hover span:not(.cb-glyph),
a.cb-text-icon-left-sm.cb-hover span:not(.cb-icon), a.cb-text-icon-left-sm:active span:not(.cb-glyph),
a.cb-text-icon-left-sm:active span:not(.cb-icon), a.cb-text-icon-left-sm.cb-active span:not(.cb-glyph),
a.cb-text-icon-left-sm.cb-active span:not(.cb-icon), a.cb-text-icon-right-sm:hover span:not(.cb-glyph),
a.cb-text-icon-right-sm:hover span:not(.cb-icon), a.cb-text-icon-right-sm.cb-hover span:not(.cb-glyph),
a.cb-text-icon-right-sm.cb-hover span:not(.cb-icon), a.cb-text-icon-right-sm:active span:not(.cb-glyph),
a.cb-text-icon-right-sm:active span:not(.cb-icon), a.cb-text-icon-right-sm.cb-active span:not(.cb-glyph),
a.cb-text-icon-right-sm.cb-active span:not(.cb-icon), a.cb-text-icon-left-xs:hover span:not(.cb-glyph),
a.cb-text-icon-left-xs:hover span:not(.cb-icon), a.cb-text-icon-left-xs.cb-hover span:not(.cb-glyph),
a.cb-text-icon-left-xs.cb-hover span:not(.cb-icon), a.cb-text-icon-left-xs:active span:not(.cb-glyph),
a.cb-text-icon-left-xs:active span:not(.cb-icon), a.cb-text-icon-left-xs.cb-active span:not(.cb-glyph),
a.cb-text-icon-left-xs.cb-active span:not(.cb-icon), a.cb-text-icon-right-xs:hover span:not(.cb-glyph),
a.cb-text-icon-right-xs:hover span:not(.cb-icon), a.cb-text-icon-right-xs.cb-hover span:not(.cb-glyph),
a.cb-text-icon-right-xs.cb-hover span:not(.cb-icon), a.cb-text-icon-right-xs:active span:not(.cb-glyph),
a.cb-text-icon-right-xs:active span:not(.cb-icon), a.cb-text-icon-right-xs.cb-active span:not(.cb-glyph),
a.cb-text-icon-right-xs.cb-active span:not(.cb-icon) {
  text-decoration: underline;
}

.cb-text-icon-overline {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.cb-text-icon-overline .cb-glyph,
.cb-text-icon-overline .cb-icon {
  margin-right: 8px;
  color: #324dc7;
}
.cb-text-icon-overline p,
.cb-text-icon-overline h4,
.cb-text-icon-overline h5 {
  font-family: Roboto !important;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}

.cb-byline {
  display: flex;
  flex-wrap: nowrap;
}
.cb-byline > a {
  display: flex;
  flex-wrap: nowrap;
  color: #1e1e1e;
  text-decoration: none;
}
.cb-byline > a:hover, .cb-byline > a.cb-hover, .cb-byline > a:focus, .cb-byline > a.cb-focus {
  color: #1e1e1e;
}
.cb-byline > a:active, .cb-byline > a.cb-active {
  color: #505050;
}
.cb-byline > a:visited, .cb-byline > a.cb-visited {
  color: #1e1e1e;
}
.cb-byline > a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-byline > a.cb-disabled:hover, .cb-byline > a.cb-disabled.cb-hover, .cb-byline > a.cb-disabled:focus, .cb-byline > a.cb-disabled.cb-focus, .cb-byline > a.cb-disabled.cb-active, .cb-byline > a.cb-disabled:visited, .cb-byline > a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-byline > a:hover p {
  text-decoration: underline;
}
.cb-byline .cb-glyph-avatar,
.cb-byline .cb-icon-avatar {
  min-width: 2em;
}
.cb-byline .cb-glyph-avatar + .cb-byline-content,
.cb-byline .cb-icon-avatar + .cb-byline-content {
  margin-left: 8px;
}
.cb-byline .cb-byline-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.cb-byline .cb-byline-content p {
  font-size: 0.875rem;
  line-height: 1.1428571429em;
}
@media (min-width: 1024px) {
  .cb-byline .cb-glyph-avatar:not(.cb-glyph-sm),
  .cb-byline .cb-icon-avatar:not(.cb-glyph-sm) {
    min-width: 3em;
  }
  .cb-byline .cb-glyph-avatar:not(.cb-glyph-sm) + .cb-byline-content,
  .cb-byline .cb-icon-avatar:not(.cb-glyph-sm) + .cb-byline-content {
    margin-left: 16px;
  }
  .cb-byline .cb-byline-content .cb-meta-tags {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
    font-weight: 500;
    line-height: 1.5em;
  }
  .cb-byline .cb-byline-content p {
    font-size: 1rem;
    line-height: 1.5em;
  }
}

.cb-meta-tags,
.cb-meta-tags-large {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  font-size: 0.75rem;
  line-height: 1.3333333333em;
  font-weight: 500;
  line-height: 2em;
}
.cb-meta-tags p,
.cb-meta-tags-large p {
  font-size: 0.75rem;
  line-height: 1.3333333333em;
  font-weight: 500;
  line-height: 2em;
}
.cb-meta-tags li,
.cb-meta-tags-large li {
  display: flex;
  align-items: center;
}
.cb-meta-tags li::after,
.cb-meta-tags-large li::after {
  content: "|";
  display: inline-flex;
  color: #b2b2b2;
  padding: 0 12px;
}
.cb-meta-tags li:last-of-type::after,
.cb-meta-tags-large li:last-of-type::after {
  content: "";
  padding: 0;
}
.cb-meta-tags a,
.cb-meta-tags-large a {
  color: #1e1e1e;
  text-decoration: none;
}
.cb-meta-tags a:hover, .cb-meta-tags a.cb-hover, .cb-meta-tags a:focus, .cb-meta-tags a.cb-focus,
.cb-meta-tags-large a:hover,
.cb-meta-tags-large a.cb-hover,
.cb-meta-tags-large a:focus,
.cb-meta-tags-large a.cb-focus {
  color: #1e1e1e;
}
.cb-meta-tags a:active, .cb-meta-tags a.cb-active,
.cb-meta-tags-large a:active,
.cb-meta-tags-large a.cb-active {
  color: #505050;
}
.cb-meta-tags a:visited, .cb-meta-tags a.cb-visited,
.cb-meta-tags-large a:visited,
.cb-meta-tags-large a.cb-visited {
  color: #1e1e1e;
}
.cb-meta-tags a.cb-disabled,
.cb-meta-tags-large a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-meta-tags a.cb-disabled:hover, .cb-meta-tags a.cb-disabled.cb-hover, .cb-meta-tags a.cb-disabled:focus, .cb-meta-tags a.cb-disabled.cb-focus, .cb-meta-tags a.cb-disabled.cb-active, .cb-meta-tags a.cb-disabled:visited, .cb-meta-tags a.cb-disabled.cb-visited,
.cb-meta-tags-large a.cb-disabled:hover,
.cb-meta-tags-large a.cb-disabled.cb-hover,
.cb-meta-tags-large a.cb-disabled:focus,
.cb-meta-tags-large a.cb-disabled.cb-focus,
.cb-meta-tags-large a.cb-disabled.cb-active,
.cb-meta-tags-large a.cb-disabled:visited,
.cb-meta-tags-large a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-meta-tags a:hover,
.cb-meta-tags-large a:hover {
  text-decoration: underline;
}

.cb-meta-tags-large {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}
.cb-meta-tags-large p {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-weight: 500;
}

.cb-card-label {
  padding: 3px;
  margin: 0;
  color: #505050;
  font-size: 0.75rem;
  line-height: 1em;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-flex;
  max-width: fit-content;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.cb-date-time-tags {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  margin-top: auto;
}
.cb-date-time-tags p {
  font-size: 14px;
  line-height: 24px;
  display: inline-flex;
}
.cb-date-time-tags p + p {
  margin: 0;
}
.cb-date-time-tags p:first-of-type {
  position: relative;
  margin-right: 32px;
}
.cb-date-time-tags p:first-of-type::after {
  content: " ";
  width: 1px;
  height: 24px;
  background-color: #d9d9d9;
  position: absolute;
  right: -16px;
}
.cb-date-time-tags p:first-of-type:last-of-type::after {
  background-color: transparent;
  margin: 0;
  width: 0;
}
.cb-date-time-tags p.cb-event-stamp {
  background-color: #d9d9d9;
  padding: 0 8px;
}

.cb-text-icon-block .flex-shrink-0 {
  line-height: 0;
}

.cb-score-metric {
  text-align: center;
  max-width: fit-content;
}
.cb-score-metric .cb-score-label {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  padding: 0 24px 8px 24px;
  border-bottom: 1px solid #1e1e1e;
}
.cb-score-metric .cb-score-value {
  font-size: 4.5rem;
  line-height: 1em;
  padding: 8px 24px 0 24px;
}

.cb-fixed-icon-top-left {
  position: relative;
}
.cb-fixed-icon-top-left [class^=cb-glyph-content],
.cb-fixed-icon-top-left [class^=cb-icon],
.cb-fixed-icon-top-left [class^=cb-ilus] {
  position: absolute;
  top: 24px;
  left: 24px;
}

.cb-fixed-icon-top-right {
  position: relative;
}
.cb-fixed-icon-top-right [class^=cb-glyph-content],
.cb-fixed-icon-top-right [class^=cb-icon],
.cb-fixed-icon-top-right [class^=cb-ilus] {
  position: absolute;
  top: 24px;
  right: 24px;
}

.cb-video-block {
  position: relative;
}
.cb-video-block .cb-video-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.cb-video-block .cb-video-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cb-video-block .cb-video-effect-col {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cb-video-block-col {
  position: relative;
}
.cb-video-block-col .cb-video-effect {
  position: absolute;
  top: 0;
  left: 12px;
  width: calc(100% - 24px);
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}