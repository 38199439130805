.cb-menu-bar.cb-menu-bar-right {
  box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  position: fixed;
  right: 0;
  min-height: 100vh;
  z-index: 1023;
}
.cb-menu-bar.cb-menu-bar-left {
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  position: fixed;
  left: 0;
  min-height: 100vh;
  z-index: 1023;
}
.cb-menu-bar.cb-menu-bar-top {
  padding-bottom: 24px;
  padding-top: 24px;
  position: fixed;
  top: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 1024;
}
.cb-menu-bar.cb-menu-bar-bottom {
  padding-bottom: 24px;
  padding-top: 24px;
  position: fixed;
  bottom: 0;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 1025;
}