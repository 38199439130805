@charset "UTF-8";
.cb-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 24px 0;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}
.cb-breadcrumb a {
  color: #1e1e1e;
}
.cb-breadcrumb a:hover, .cb-breadcrumb a.cb-hover, .cb-breadcrumb a:focus, .cb-breadcrumb a.cb-focus {
  color: #1e1e1e;
}
.cb-breadcrumb a:active, .cb-breadcrumb a.cb-active {
  color: #505050;
}
.cb-breadcrumb a:visited, .cb-breadcrumb a.cb-visited {
  color: #1e1e1e;
}
.cb-breadcrumb a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-breadcrumb a.cb-disabled:hover, .cb-breadcrumb a.cb-disabled.cb-hover, .cb-breadcrumb a.cb-disabled:focus, .cb-breadcrumb a.cb-disabled.cb-focus, .cb-breadcrumb a.cb-disabled.cb-active, .cb-breadcrumb a.cb-disabled:visited, .cb-breadcrumb a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-breadcrumb li:first-of-type:not(:last-of-type)::after {
  content: "/ ";
  padding: 0 12px;
  color: #1e1e1e;
}
.cb-breadcrumb li + li::after {
  content: "/ ";
  padding: 0 12px;
  color: #1e1e1e;
}
.cb-breadcrumb li + li:last-of-type::after {
  content: "";
  padding: 0;
}