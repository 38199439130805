.cb-popover {
  position: absolute;
  background-color: #ffffff;
  color: #1e1e1e;
  min-width: 180px;
  max-width: 360px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  z-index: 1070;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  text-align: left;
}
@media (max-width: 767.98px) {
  .cb-popover {
    max-width: calc(100vw - 48px);
  }
}
.cb-popover[aria-hidden=false] {
  display: block;
}
.cb-popover[aria-hidden=true] {
  display: none;
}
.cb-popover[data-popper-placement^=top], .cb-popover[data-popper-placement^=bottom], .cb-popover[data-popper-placement^=right], .cb-popover[data-popper-placement^=left] {
  opacity: 1;
}
.cb-popover .cb-popover-inner {
  padding: 24px;
}
.cb-popover .cb-popover-inner .cb-popover-header {
  margin-bottom: 24px;
}
.cb-popover .cb-popover-inner .cb-popover-header .cb-popover-title {
  font-weight: 400;
  font-size: 1.4375rem;
  line-height: 1.0434782609em;
}
.cb-popover .cb-popover-inner .cb-popover-header.cb-popover-close {
  display: flex;
  justify-content: space-between;
}
.cb-popover .cb-popover-inner .cb-popover-header.cb-popover-close .cb-popover-title {
  line-height: 32px;
}
.cb-popover .cb-popover-inner .cb-popover-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
}
.cb-popover .cb-popover-inner .cb-popover-footer .cb-btn-naked.cb-no-padding {
  align-self: center;
}

.cb-btn-row .cb-popover {
  font-size: 1rem;
}
.cb-btn-row .cb-popover .cb-btn {
  flex-shrink: unset;
  margin: 0;
}

.cb-drupal-popover .cb-btn-circle:focus, .cb-drupal-popover .cb-btn-circle:hover {
  box-shadow: 0 0 0 2px #324dc7;
  border: 1px solid #ffffff;
}
.cb-drupal-popover.cb-popover-light .cb-btn-circle {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  color: #1e1e1e !important;
}
.cb-drupal-popover.cb-popover-light .cb-btn-circle:focus, .cb-drupal-popover.cb-popover-light .cb-btn-circle:hover {
  box-shadow: 0 0 0 2px #ffffff;
  border: 1px solid #1e1e1e !important;
}