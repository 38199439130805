/* All high contrast styling rules */
.cb-spinner {
  width: 4rem;
  height: 4rem;
  position: relative;
}
.cb-spinner > *:not(p) {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.cb-spinner > *:not(p)::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 12%;
  height: 12%;
  background-color: #1e1e1e;
  border-radius: 100%;
  animation: cb-spinnerBounceDelay 1.2s infinite ease-in-out both;
}
@media (prefers-reduced-motion: reduce) {
  .cb-spinner > *:not(p)::before {
    animation: none;
  }
}
.cb-spinner > *:not(p):nth-child(2) {
  transform: rotate(30deg);
}
.cb-spinner > *:not(p):nth-child(2)::before {
  animation-delay: -1.1s;
}
.cb-spinner > *:not(p):nth-child(3) {
  transform: rotate(60deg);
}
.cb-spinner > *:not(p):nth-child(3)::before {
  animation-delay: -1s;
}
.cb-spinner > *:not(p):nth-child(4) {
  transform: rotate(90deg);
}
.cb-spinner > *:not(p):nth-child(4)::before {
  animation-delay: -0.9s;
}
.cb-spinner > *:not(p):nth-child(5) {
  transform: rotate(120deg);
}
.cb-spinner > *:not(p):nth-child(5)::before {
  animation-delay: -0.8s;
}
.cb-spinner > *:not(p):nth-child(6) {
  transform: rotate(150deg);
}
.cb-spinner > *:not(p):nth-child(6)::before {
  animation-delay: -0.7s;
}
.cb-spinner > *:not(p):nth-child(7) {
  transform: rotate(180deg);
}
.cb-spinner > *:not(p):nth-child(7)::before {
  animation-delay: -0.6s;
}
.cb-spinner > *:not(p):nth-child(8) {
  transform: rotate(210deg);
}
.cb-spinner > *:not(p):nth-child(8)::before {
  animation-delay: -0.5s;
}
.cb-spinner > *:not(p):nth-child(9) {
  transform: rotate(240deg);
}
.cb-spinner > *:not(p):nth-child(9)::before {
  animation-delay: -0.4s;
}
.cb-spinner > *:not(p):nth-child(10) {
  transform: rotate(270deg);
}
.cb-spinner > *:not(p):nth-child(10)::before {
  animation-delay: -0.3s;
}
.cb-spinner > *:not(p):nth-child(11) {
  transform: rotate(300deg);
}
.cb-spinner > *:not(p):nth-child(11)::before {
  animation-delay: -0.2s;
}
.cb-spinner > *:not(p):nth-child(12) {
  transform: rotate(330deg);
}
.cb-spinner > *:not(p):nth-child(12)::before {
  animation-delay: -0.1s;
}
.cb-spinner p {
  font-size: 0.75rem;
  line-height: 64px;
  margin-left: 11px;
}
.cb-spinner.cb-spinner-light > *::before {
  background-color: #ffffff;
}
.cb-spinner.cb-palette-blue2 > *:not(p)::before {
  background-color: #0077c8;
}
.cb-spinner.cb-palette-blue2 .cb-spinner-indicator {
  color: #0077c8;
}
.cb-spinner.cb-palette-blue5 > *:not(p)::before {
  background-color: #324dc7;
}
.cb-spinner.cb-palette-blue5 .cb-spinner-indicator {
  color: #324dc7;
}
.cb-spinner.cb-palette-purple1 > *:not(p)::before {
  background-color: #702f8a;
}
.cb-spinner.cb-palette-purple1 .cb-spinner-indicator {
  color: #702f8a;
}
.cb-spinner.cb-palette-green1 > *:not(p)::before {
  background-color: #3a913f;
}
.cb-spinner.cb-palette-green1 .cb-spinner-indicator {
  color: #3a913f;
}

.cb-spinner.cb-spinner-16 {
  width: 16px;
  height: 16px;
}

.cb-spinner.cb-spinner-24 {
  width: 24px;
  height: 24px;
}

.cb-spinner.cb-spinner-32 {
  width: 32px;
  height: 32px;
}

.cb-spinner.cb-spinner-64 {
  width: 64px;
  height: 64px;
}

.cb-spinner .cb-spinner-indicator,
.cb-progress-spinner .cb-spinner-indicator {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  color: #1e1e1e;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
}

.cb-progress-spinner {
  height: 4rem;
  width: 4rem;
  position: relative;
}
.cb-progress-spinner div {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
.cb-progress-spinner .cb-spinner-indicator {
  position: absolute;
  border-radius: 50%;
  top: 8px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color: #ffffff;
}
.cb-progress-spinner .cb-spinner-background {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: #d9d9d9;
}
.cb-progress-spinner .cb-spinner-rotate {
  clip: rect(0 32px 4rem 0);
  background-color: #1e1e1e;
}
.cb-progress-spinner .cb-spinner-left {
  clip: rect(0 32px 64px 0);
  opacity: 1;
  background-color: #d9d9d9;
  box-shadow: 0px 0px 0px 2px #ffffff;
}
.cb-progress-spinner .cb-spinner-right {
  clip: rect(0 32px 64px 0);
  transform: rotate(180deg);
  opacity: 0;
  background-color: #1e1e1e;
}
.cb-progress-spinner.cb-spinner-light .cb-spinner-background,
.cb-progress-spinner.cb-spinner-light .cb-spinner-left {
  background-color: gray;
  box-shadow: none;
}
.cb-progress-spinner.cb-spinner-light .cb-spinner-rotate,
.cb-progress-spinner.cb-spinner-light .cb-spinner-right {
  background-color: #ffffff;
}
.cb-progress-spinner.cb-spinner-light .cb-spinner-indicator {
  color: #ffffff;
}
.cb-progress-spinner.cb-palette-blue2 .cb-spinner-background,
.cb-progress-spinner.cb-palette-blue2 .cb-spinner-left {
  background-color: #e6f1fa;
}
.cb-progress-spinner.cb-palette-blue2 .cb-spinner-rotate,
.cb-progress-spinner.cb-palette-blue2 .cb-spinner-right {
  background-color: #0077c8;
}
.cb-progress-spinner.cb-palette-blue2 .cb-spinner-indicator {
  color: #0077c8;
}
.cb-progress-spinner.cb-palette-blue5::before {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-left {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-background,
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-left {
  background-color: #ebedf9;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-rotate,
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-right {
  background-color: #324dc7;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-indicator {
  color: #324dc7;
}
.cb-progress-spinner.cb-palette-blue5::before {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-left {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-purple1 .cb-spinner-background,
.cb-progress-spinner.cb-palette-purple1 .cb-spinner-left {
  background-color: #f1eaf3;
}
.cb-progress-spinner.cb-palette-purple1 .cb-spinner-rotate,
.cb-progress-spinner.cb-palette-purple1 .cb-spinner-right {
  background-color: #702f8a;
}
.cb-progress-spinner.cb-palette-purple1 .cb-spinner-indicator {
  color: #702f8a;
}
.cb-progress-spinner.cb-palette-blue5::before {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-left {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-green1 .cb-spinner-background,
.cb-progress-spinner.cb-palette-green1 .cb-spinner-left {
  background-color: #ebf4ec;
}
.cb-progress-spinner.cb-palette-green1 .cb-spinner-rotate,
.cb-progress-spinner.cb-palette-green1 .cb-spinner-right {
  background-color: #3a913f;
}
.cb-progress-spinner.cb-palette-green1 .cb-spinner-indicator {
  color: #3a913f;
}
.cb-progress-spinner.cb-palette-blue5::before {
  background-color: #e6edf8;
}
.cb-progress-spinner.cb-palette-blue5 .cb-spinner-left {
  background-color: #e6edf8;
}

@media screen and (-ms-high-contrast: active) {
  .cb-spinner > *:not(p)::before {
    background-color: transparent !important;
  }
  .cb-spinner .ms-high-contrast {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
    color: #1e1e1e;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .cb-spinner .ms-high-contrast + .cb-spinner-indicator {
    margin-top: 8px;
  }
}
@media (forced-colors: active) {
  .cb-spinner > *:not(p)::before {
    background-color: transparent !important;
  }
  .cb-spinner .ms-high-contrast {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
    color: #1e1e1e;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .cb-spinner .ms-high-contrast + .cb-spinner-indicator {
    margin-top: 8px;
  }
}
@-webkit-keyframes cb-spinnerBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes cb-spinnerBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes toggle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}