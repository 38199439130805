.cb-pagination {
  padding: 0;
  margin: 0;
  font-size: 0;
  display: inline-flex;
  flex-wrap: wrap;
}
.cb-pagination a {
  color: #1e1e1e;
}
.cb-pagination a:hover, .cb-pagination a.cb-hover, .cb-pagination a:focus, .cb-pagination a.cb-focus {
  color: #1e1e1e;
}
.cb-pagination a:active, .cb-pagination a.cb-active {
  color: #505050;
}
.cb-pagination a:visited, .cb-pagination a.cb-visited {
  color: #1e1e1e;
}
.cb-pagination a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-pagination a.cb-disabled:hover, .cb-pagination a.cb-disabled.cb-hover, .cb-pagination a.cb-disabled:focus, .cb-pagination a.cb-disabled.cb-focus, .cb-pagination a.cb-disabled.cb-active, .cb-pagination a.cb-disabled:visited, .cb-pagination a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-pagination a.cb-disabled, .cb-pagination a:disabled {
  color: #b2b2b2;
}
.cb-pagination > li {
  display: inline-flex;
}
.cb-pagination > li .cb-btn-square {
  border-color: #d9d9d9;
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.cb-pagination > li .cb-btn-square.cb-active {
  background-color: #b2b2b2;
  border: 1px solid #d9d9d9 !important;
}
.cb-pagination > li .cb-btn-square:focus, .cb-pagination > li .cb-btn-square.cb-focus {
  border: 1px solid #1e1e1e;
}
.cb-pagination > li .cb-btn-square:disabled.cb-active, .cb-pagination > li .cb-btn-square:disabled.active, .cb-pagination > li .cb-btn-square.cb-disabled.cb-active, .cb-pagination > li .cb-btn-square.cb-disabled.active {
  color: #f0f0f0;
}
.cb-pagination > li:first-child .cb-btn-square {
  border-left-width: 1px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.cb-pagination > li:last-child .cb-btn-square {
  border-right-width: 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}