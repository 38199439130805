.cb-masonry-column {
  column-gap: 3rem;
  transition: all 0.3s ease-in-out;
}
.cb-masonry-column .cb-masonry-item {
  -webkit-column-break-inside: avoid;
  margin-bottom: 3rem;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.cb-masonry-column:not(.chrome) .cb-masonry-item {
  display: inline-block;
}
.cb-masonry-column.cb-masonry-4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-masonry-column.cb-masonry-4 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-masonry-column.cb-masonry-4 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-masonry-column.cb-masonry-4 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.cb-masonry-column.cb-masonry-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-masonry-column.cb-masonry-3 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-masonry-column.cb-masonry-3 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.cb-masonry-column.cb-masonry-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
@media (max-width: 1023.98px) {
  .cb-masonry-column.cb-masonry-2 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.cb-masonry-row {
  display: flex;
  flex-flow: column wrap;
  overflow-x: hidden;
}
.cb-masonry-row .cb-masonry-item {
  margin: 0 3rem 3rem 0;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-masonry-row .cb-masonry-item {
    margin: 0 0 3rem 0;
  }
}
.cb-masonry-row.cb-masonry-4 .cb-masonry-item {
  width: calc((100% - 144px) / 4);
}
.cb-masonry-row.cb-masonry-4 .cb-masonry-item[data-cb-oder="4"] {
  margin-right: 0;
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-masonry-row.cb-masonry-4 .cb-masonry-item {
    width: calc((100% - 96px) / 3);
  }
  .cb-masonry-row.cb-masonry-4 .cb-masonry-item[data-cb-oder="3"] {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-masonry-row.cb-masonry-4 .cb-masonry-item {
    width: calc((100% - 48px) / 2);
  }
  .cb-masonry-row.cb-masonry-4 .cb-masonry-item[data-cb-oder="2"] {
    margin-right: 0;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-masonry-row.cb-masonry-4 .cb-masonry-item {
    width: 100%;
  }
}
.cb-masonry-row.cb-masonry-3 .cb-masonry-item {
  width: calc((100% - 48px) / 3);
}
.cb-masonry-row.cb-masonry-3 .cb-masonry-item[data-cb-oder="3"] {
  margin-right: 0;
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-masonry-row.cb-masonry-3 .cb-masonry-item {
    width: calc((100% - 24px) / 2);
  }
  .cb-masonry-row.cb-masonry-3 .cb-masonry-item[data-cb-oder="2"] {
    margin-right: 0;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-masonry-row.cb-masonry-3 .cb-masonry-item {
    width: 100%;
  }
}
.cb-masonry-row.cb-masonry-2 .cb-masonry-item {
  width: calc((100% - 24px) / 2);
}
.cb-masonry-row.cb-masonry-2 .cb-masonry-item[data-cb-oder="2"] {
  margin-right: 0;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-masonry-row.cb-masonry-2 .cb-masonry-item {
    width: 100%;
  }
}