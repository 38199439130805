.cb-slider,
.cb-slider * {
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.cb-slider {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #d9d9d9;
}
.cb-slider .cb-slider-base,
.cb-slider .cb-slider-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.cb-slider .cb-slider-connects {
  overflow: hidden;
  z-index: 0;
  border-radius: 2px;
}
.cb-slider .cb-slider-connects .cb-slider-connect,
.cb-slider .cb-slider-connects .cb-slider-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  transform-style: flat;
}
.cb-slider .cb-slider-connects .cb-slider-origin {
  height: 0;
  left: 0;
  right: auto;
}
.cb-slider .cb-slider-connects .cb-slider-connect {
  background: #324dc7;
}

.cb-slider-handle {
  backface-visibility: hidden;
  position: absolute;
  width: 8px;
  height: 8px;
  right: -5px;
  top: -8px;
  transition: box-shadow 0.3s ease-out;
  border-radius: 50%;
  background: #324dc7;
  border: 2px solid #324dc7;
  box-sizing: content-box;
  cursor: default;
}
.cb-slider-handle:hover {
  background: #ffffff;
  cursor: pointer;
}
.cb-slider-handle.cb-slider-active, .cb-slider-handle:focus {
  width: 18px;
  height: 18px;
  right: -10px;
  top: -14px;
  cursor: pointer;
  outline: none;
  background: #1e1e1e;
  border: 2px solid #ffffff;
  box-shadow: 0 0 0 1px #324dc7;
}
.cb-slider-handle.cb-slider-active:after, .cb-slider-handle:focus:after {
  display: block;
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  right: 3px;
  top: 3px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #ffffff;
}
.cb-slider-handle.cb-slider-active {
  background: #324dc7;
  border: 2px solid #ffffff;
  box-shadow: 0 0 0 1px #324dc7;
}
.cb-slider-handle.cb-slider-active:after {
  display: none;
}

.cb-slider-touch-area {
  height: 24px;
  width: 24px;
  margin-top: -8px;
  margin-left: -8px;
}

.cb-slider-state-tap .cb-slider-connect,
.cb-slider-state-tap .cb-slider-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

/* Base;
 *
 */
.cb-slider-pips,
.cb-slider-pips * {
  box-sizing: border-box;
}

.cb-slider-pips {
  position: absolute;
  color: #888888;
}

/* Values;
  *
  */
.cb-slider-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
  color: #505050;
  line-height: 32px;
}

/* Markings;
  *
  */
.cb-slider-marker {
  position: absolute;
  background: #888888;
}

.cb-slider-marker-large {
  background: #505050;
}

.cb-slider-pips {
  height: 24px;
  top: 0;
  left: 0;
  width: 100%;
}

.cb-slider-value {
  transform: translate(-50%, 50%);
}

.cb-slider-marker.cb-slider-marker {
  margin-left: -1px;
  width: 1px;
  height: 4px;
}

.cb-slider-marker.cb-slider-marker-large {
  height: 12px;
}

.cb-slider-tooltip {
  display: none;
  position: absolute;
  background: #505050;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1070;
  font-size: 14px;
  padding: 0 6px;
  white-space: nowrap;
  transform: translate(-50%, 0);
  left: 50%;
  top: -42px;
}

.cb-slider-handle:not([aria-disabled]).cb-slider-active .cb-slider-tooltip, .cb-slider-handle:not([aria-disabled]):focus .cb-slider-tooltip {
  display: block;
  top: -36px;
}

.cb-slider-sticky-tooltip .cb-slider-tooltip {
  display: block;
}

.cb-slider-container {
  display: flex;
  width: 100%;
}
.cb-slider-container.cb-slider-single {
  align-items: center;
}
.cb-slider-container.cb-slider-range {
  align-items: flex-end;
}
.cb-slider-container .cb-input-container {
  width: 20%;
}
.cb-slider-container .cb-input-container .cb-input-slider {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.cb-slider-container .cb-input-container .cb-input-slider label {
  margin-left: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}
.cb-slider-container .cb-input-container .cb-input-slider input {
  min-width: 64px;
  background-color: #f5f7fc;
  font-size: 14px;
  line-height: 1.7142857143em;
  padding: 4px 11px 4px 12px;
  line-height: 1.5em;
  border: 1px solid #f5f7fc;
  border-radius: 8px;
}
.cb-slider-container .cb-input-container .cb-input-slider input:disabled, .cb-slider-container .cb-input-container .cb-input-slider input:read-only {
  color: #1e1e1e;
  opacity: 1;
  cursor: not-allowed !important;
  border: 1px solid #d9d9d9 !important;
  background: #f0f0f0 !important;
  cursor: not-allowed;
  -webkit-text-fill-color: #505050; /* Override iOS / Android font color change */
  -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
}
.cb-slider-container .cb-input-container .cb-input-slider input:disabled:focus, .cb-slider-container .cb-input-container .cb-input-slider input:read-only:focus {
  box-shadow: none;
  outline: none;
}
.cb-slider-container .cb-input-container .cb-input-slider input:read-only:not(:disabled) {
  cursor: default !important;
  border-color: #f0f0f0 !important;
}
.cb-slider-container .cb-input-container .cb-input-slider + .cb-input-slider {
  margin-top: 8px;
}
.cb-slider-container.cb-slider-stacked {
  flex-direction: column;
  align-items: flex-start !important;
}
.cb-slider-container.cb-slider-stacked .cb-input-container {
  display: flex;
}
.cb-slider-container.cb-slider-stacked .cb-input-container .cb-input-slider {
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
}
.cb-slider-container.cb-slider-stacked .cb-input-container .cb-input-slider label {
  margin-left: 0;
  margin-top: 8px;
}
.cb-slider-container.cb-slider-stacked .cb-input-container .cb-input-slider + .cb-input-slider {
  margin-left: 8px;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-slider-container {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .cb-slider-container .cb-input-container {
    display: flex;
  }
  .cb-slider-container .cb-input-container .cb-input-slider {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .cb-slider-container .cb-input-container .cb-input-slider label {
    margin-left: 0;
    margin-top: 8px;
  }
  .cb-slider-container .cb-input-container .cb-input-slider + .cb-input-slider {
    margin-left: 8px;
  }
}
.cb-slider-min,
.cb-slider-max {
  display: inline-flex;
  font-size: 14px;
  line-height: 1.7142857143em;
  padding: 7px 15px;
  line-height: 1.5em;
}

/* Disabled state; */
[disabled].cb-slider,
[disabled] .cb-slider, [disabled].cb-slider-handle,
[disabled] .cb-slider-handle,
[aria-disabled].cb-slider,
[aria-disabled] .cb-slider,
[aria-disabled].cb-slider-handle,
[aria-disabled] .cb-slider-handle {
  cursor: not-allowed;
}
[disabled].cb-slider .cb-slider-connect,
[disabled] .cb-slider .cb-slider-connect,
[aria-disabled].cb-slider .cb-slider-connect,
[aria-disabled] .cb-slider .cb-slider-connect {
  background: #f0f0f0;
}
[disabled].cb-slider .cb-slider-handle, [disabled].cb-slider .cb-slider-handle:hover, [disabled].cb-slider .cb-slider-handle:focus, [disabled].cb-slider .cb-slider-handle.cb-slider-active,
[disabled] .cb-slider .cb-slider-handle,
[disabled] .cb-slider .cb-slider-handle:hover,
[disabled] .cb-slider .cb-slider-handle:focus,
[disabled] .cb-slider .cb-slider-handle.cb-slider-active,
[aria-disabled].cb-slider .cb-slider-handle,
[aria-disabled].cb-slider .cb-slider-handle:hover,
[aria-disabled].cb-slider .cb-slider-handle:focus,
[aria-disabled].cb-slider .cb-slider-handle.cb-slider-active,
[aria-disabled] .cb-slider .cb-slider-handle,
[aria-disabled] .cb-slider .cb-slider-handle:hover,
[aria-disabled] .cb-slider .cb-slider-handle:focus,
[aria-disabled] .cb-slider .cb-slider-handle.cb-slider-active {
  width: 8px !important;
  height: 8px !important;
  right: -5px !important;
  top: -8px !important;
  background: #f0f0f0 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
[disabled].cb-slider .cb-slider-handle:after, [disabled].cb-slider .cb-slider-handle:hover:after, [disabled].cb-slider .cb-slider-handle:focus:after, [disabled].cb-slider .cb-slider-handle.cb-slider-active:after,
[disabled] .cb-slider .cb-slider-handle:after,
[disabled] .cb-slider .cb-slider-handle:hover:after,
[disabled] .cb-slider .cb-slider-handle:focus:after,
[disabled] .cb-slider .cb-slider-handle.cb-slider-active:after,
[aria-disabled].cb-slider .cb-slider-handle:after,
[aria-disabled].cb-slider .cb-slider-handle:hover:after,
[aria-disabled].cb-slider .cb-slider-handle:focus:after,
[aria-disabled].cb-slider .cb-slider-handle.cb-slider-active:after,
[aria-disabled] .cb-slider .cb-slider-handle:after,
[aria-disabled] .cb-slider .cb-slider-handle:hover:after,
[aria-disabled] .cb-slider .cb-slider-handle:focus:after,
[aria-disabled] .cb-slider .cb-slider-handle.cb-slider-active:after {
  display: none;
}
[disabled].cb-slider .cb-slider-handle.cb-slider-active .cb-slider-tooltip, [disabled].cb-slider .cb-slider-handle:focus .cb-slider-tooltip,
[disabled] .cb-slider .cb-slider-handle.cb-slider-active .cb-slider-tooltip,
[disabled] .cb-slider .cb-slider-handle:focus .cb-slider-tooltip,
[aria-disabled].cb-slider .cb-slider-handle.cb-slider-active .cb-slider-tooltip,
[aria-disabled].cb-slider .cb-slider-handle:focus .cb-slider-tooltip,
[aria-disabled] .cb-slider .cb-slider-handle.cb-slider-active .cb-slider-tooltip,
[aria-disabled] .cb-slider .cb-slider-handle:focus .cb-slider-tooltip {
  top: -42px;
}
[disabled].cb-slider-handle, [disabled].cb-slider-handle:hover, [disabled].cb-slider-handle:focus, [disabled].cb-slider-handle.cb-slider-active,
[disabled] .cb-slider-handle,
[disabled] .cb-slider-handle:hover,
[disabled] .cb-slider-handle:focus,
[disabled] .cb-slider-handle.cb-slider-active,
[aria-disabled].cb-slider-handle,
[aria-disabled].cb-slider-handle:hover,
[aria-disabled].cb-slider-handle:focus,
[aria-disabled].cb-slider-handle.cb-slider-active,
[aria-disabled] .cb-slider-handle,
[aria-disabled] .cb-slider-handle:hover,
[aria-disabled] .cb-slider-handle:focus,
[aria-disabled] .cb-slider-handle.cb-slider-active {
  width: 8px !important;
  height: 8px !important;
  right: -5px !important;
  top: -8px !important;
  background: #f0f0f0 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
[disabled].cb-slider-handle:after, [disabled].cb-slider-handle:hover:after, [disabled].cb-slider-handle:focus:after, [disabled].cb-slider-handle.cb-slider-active:after,
[disabled] .cb-slider-handle:after,
[disabled] .cb-slider-handle:hover:after,
[disabled] .cb-slider-handle:focus:after,
[disabled] .cb-slider-handle.cb-slider-active:after,
[aria-disabled].cb-slider-handle:after,
[aria-disabled].cb-slider-handle:hover:after,
[aria-disabled].cb-slider-handle:focus:after,
[aria-disabled].cb-slider-handle.cb-slider-active:after,
[aria-disabled] .cb-slider-handle:after,
[aria-disabled] .cb-slider-handle:hover:after,
[aria-disabled] .cb-slider-handle:focus:after,
[aria-disabled] .cb-slider-handle.cb-slider-active:after {
  display: none;
}
[disabled] .cb-slider-tooltip,
[aria-disabled] .cb-slider-tooltip {
  background-color: #d9d9d9;
  color: #1e1e1e;
  border: 1px solid #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.cb-slider.cb-slider-black .cb-slider-connects .cb-slider-connect {
  background: #1e1e1e;
}
.cb-slider.cb-slider-black .cb-slider-handle {
  background: #1e1e1e;
  border: 2px solid #1e1e1e;
}
.cb-slider.cb-slider-black .cb-slider-handle:hover {
  background: #ffffff;
  cursor: pointer;
}
.cb-slider.cb-slider-black .cb-slider-handle.cb-slider-active, .cb-slider.cb-slider-black .cb-slider-handle:focus {
  background: #324dc7;
  box-shadow: 0 0 0 1px #1e1e1e;
  border-color: #ffffff;
}
.cb-slider.cb-slider-black .cb-slider-handle.cb-slider-active {
  background: #1e1e1e;
  box-shadow: 0 0 0 1px #324dc7;
}