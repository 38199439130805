.cb-input-mask.cb-floating-label .cb-input-icon-right label,
.cb-input-date.cb-floating-label .cb-input-icon-right label {
  width: calc(100% - 50px);
  justify-content: flex-start;
}
.cb-input-mask.cb-floating-label input::placeholder,
.cb-input-date.cb-floating-label input::placeholder {
  color: transparent;
  transition: color 0.35s ease;
}
.cb-input-mask.cb-floating-label .cb-value-fl,
.cb-input-mask.cb-floating-label .cb-focus-fl,
.cb-input-date.cb-floating-label .cb-value-fl,
.cb-input-date.cb-floating-label .cb-focus-fl {
  width: auto;
  justify-content: center;
}
.cb-input-mask.cb-floating-label .cb-value-fl + input::placeholder,
.cb-input-mask.cb-floating-label .cb-focus-fl + input::placeholder,
.cb-input-date.cb-floating-label .cb-value-fl + input::placeholder,
.cb-input-date.cb-floating-label .cb-focus-fl + input::placeholder {
  color: #888888;
}
.cb-input-mask.cb-floating-label .cb-input-icon-right .cb-value-fl,
.cb-input-mask.cb-floating-label .cb-input-icon-right .cb-focus-fl,
.cb-input-date.cb-floating-label .cb-input-icon-right .cb-value-fl,
.cb-input-date.cb-floating-label .cb-input-icon-right .cb-focus-fl {
  width: auto;
  justify-content: center;
}